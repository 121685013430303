import { useLocale } from '@/hooks/use-language';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommandEmpty, CommandGroup } from '@ui/components/command';
import { Item } from './command-menu-item';
import { Icons } from '@ui/components/icons';

export function CommandMenuHome({
  searchCases,
  searchUsers,
  setOpen,
}: {
  searchCases: () => void;
  searchUsers: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const { t, lang } = useLocale('nav');

  const runCommand = React.useCallback(
    (command: () => unknown) => {
      setOpen(false);
      command();
    },
    [setOpen]
  );

  return (
    <>
      <CommandEmpty>{t('nav:commandMenu.noResults')}</CommandEmpty>
      <CommandGroup heading={t('nav:cases')}>
        <Item
          onSelect={() => {
            searchCases();
          }}
        >
          <div className="mr-2 flex h-4 w-4 items-center justify-center">
            <Icons.circle className="h-3 w-3" />
          </div>
          {t('nav:commandMenu.searchCases')}
        </Item>
        <Item
          onSelect={() => {
            runCommand(() => navigate(`/${lang}/new-case`));
          }}
        >
          <div className="mr-2 flex h-4 w-4 items-center justify-center">
            <Icons.add className="h-3 w-3" />
          </div>
          {t('nav:newCase')}...
        </Item>
      </CommandGroup>
      <CommandGroup heading={t('nav:users')}>
        <Item
          onSelect={() => {
            searchUsers();
          }}
        >
          <div className="mr-2 flex h-4 w-4 items-center justify-center">
            <Icons.circle className="h-3 w-3" />
          </div>
          {t('nav:commandMenu.searchUsers')}
        </Item>
        <Item
          onSelect={() => {
            runCommand(() => navigate(`/${lang}/users?rejectedUsers=true`));
          }}
        >
          <div className="mr-2 flex h-4 w-4 items-center justify-center">
            <Icons.rejectedUser className="h-3 w-3" />
          </div>
          {t('nav:commandMenu.rejectedUsers')}
        </Item>
      </CommandGroup>
    </>
  );
}
