"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventBridgeAdapter = void 0;
const client_eventbridge_1 = require("@aws-sdk/client-eventbridge");
const credential_providers_1 = require("@aws-sdk/credential-providers");
class EventBridgeAdapter {
    constructor(region, busName, source, 
    // change these devProfiles based on feature you want to test
    devProfile = 'al-arc-garage-form-staging'
    // devProfile = 'al-hertz-order-form-staging'
    // devProfile = 'al-redgo-order-form-staging'
    ) {
        this.busName = busName;
        this.source = source;
        this.client = new client_eventbridge_1.EventBridgeClient({
            region,
            credentials: process.env.NODE_ENV === 'development'
                ? (0, credential_providers_1.fromSSO)({ profile: devProfile })
                : undefined,
        });
    }
    async putEvents(eventName, data) {
        await this.client.send(new client_eventbridge_1.PutEventsCommand({
            Entries: [
                {
                    Source: this.source,
                    EventBusName: this.busName,
                    DetailType: eventName,
                    Detail: JSON.stringify(data),
                },
            ],
        }));
    }
}
exports.EventBridgeAdapter = EventBridgeAdapter;
