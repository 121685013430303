import { User, UserStatus } from '@/backend/models/User';
import { Avatar, AvatarFallback } from '@ui/components/avatar';
import { Badge } from '@ui/components/badge';
import { useUser } from '@/hooks/use-auth';
import { useLocale } from '@/hooks/use-language';
import { cn } from '@ui/lib/utils';

interface Props {
  user: User;
  className?: string;
}

export function UserRow({ user, className }: Props) {
  const { t } = useLocale('users');

  const currentUser = useUser();
  const isCurrentUser = currentUser!.id === user.id;

  return (
    <div className={cn('flex items-center', className)}>
      <Avatar className="h-8 w-8">
        <AvatarFallback className="bg-slate-200 dark:bg-slate-700">
          <p className="font-semibold text-sm text-muted-foreground">
            {user.name[0]}
          </p>
        </AvatarFallback>
      </Avatar>
      <div className="ml-4 space-y-1.5 min-w-fit">
        <div className="flex items-center space-x-1.5">
          <p className="text-sm font-medium leading-none min-w-fit">
            {user.name}
          </p>
          <Badge variant={'outline'} className="-my-1">
            {t(`users:role.${user.role}`)}
          </Badge>
          {user.status === UserStatus.UNCONFIRMED && (
            <Badge variant={'destructive'} className="-my-1 min-w-fit">
              {t('users:unconfirmed')}
            </Badge>
          )}
          {isCurrentUser && (
            <Badge className="-my-1 min-w-fit">{t('users:currentUser')}</Badge>
          )}
          {user.status === UserStatus.REJECTED && (
            <Badge variant={'destructive'} className="-my-1 min-w-fit">
              {t('users:rejectedUser')}
            </Badge>
          )}
        </div>
        <p className="text-sm text-muted-foreground">{user.email}</p>
      </div>
    </div>
  );
}
