import * as React from 'react';
import { cn } from '@ui/lib/utils';
import { Button } from '@ui/components/button';
import {
  CommandDialog,
  CommandInput,
  CommandList,
} from '@ui/components/command';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import { DialogProps } from '@ui/components/dialog';
import { CommandMenuHome } from './command-menu-home-page';
import { CommandMenuCases } from './command-menu-cases';
import { CommandMenuUsers } from './command-menu-users';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { trpc } from '@/lib/trpc';
import { useDebounce } from '@/hooks/use-debounce';

export function CommandMenu({ ...props }: DialogProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');

  const navigate = useNavigate();

  const [pages, setPages] = React.useState<string[]>(['home']);
  const activePage = pages[pages.length - 1];
  const isHome = activePage === 'home';

  const { t, lang } = useLocale('nav');

  const debouncedFilter = useDebounce(searchKeyword, 500);

  const { data: cases, isLoading: isCasesLoading } =
    trpc.case.getCases.useQuery({
      limit: !searchKeyword ? 5 : undefined,
      filters: { registrationPlate: debouncedFilter },
    });

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setPages(['home']);
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const handleCaseSelect = (value: string) => {
    const { registrationplate } = z
      .object({ id: z.string(), registrationplate: z.string() })
      .parse(JSON.parse(value));
    navigate(`/${lang}/case-history?search=${registrationplate.toUpperCase()}`);
    setOpen(false);
    setSearchKeyword('');
  };

  const handleUserSelect = (value: string) => {
    const { name } = z
      .object({ id: z.string(), name: z.string() })
      .parse(JSON.parse(value));

    // transform name to upper case
    const upperCaseName = name
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');

    navigate(`/${lang}/users?search=${upperCaseName}`);
    setOpen(false);
    setSearchKeyword('');
  };

  const popPage = React.useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (isHome || searchKeyword.length) {
        return;
      }

      if (e.key === 'Backspace') {
        e.preventDefault();
        popPage();
      }
    },
    [searchKeyword.length, isHome, popPage]
  );

  const getSearchPlaceholder = () => {
    switch (activePage) {
      case 'users':
        return t('nav:commandMenu.searchUsers');
      case 'cases':
        return t('nav:commandMenu.searchCases');

      default:
        return t('nav:commandMenu.placeholder');
    }
  };

  return (
    <>
      <Button
        className={cn(
          'relative group flex justify-start items-center rounded-md px-4 py-2 font-medium text-muted-foreground hover:bg-slate-100 hover:bg-input/50 hover:text-primary bg-transparent'
        )}
        onClick={() => {
          setPages(['home']);
          setOpen(true);
        }}
        {...props}
      >
        <Icons.search className="mr-2 h-4 w-4" />
        <span>{t('nav:search')}</span>
        <kbd className="top-2.25 pointer-events-none absolute right-9 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>
        </kbd>
        <kbd className="top-2.25 pointer-events-none absolute right-3 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          onKeyDown={onKeyDown}
          placeholder={getSearchPlaceholder()}
          value={searchKeyword}
          onValueChange={setSearchKeyword}
        />
        <CommandList className="pb-1">
          {activePage === 'home' && (
            <CommandMenuHome
              searchCases={() => {
                setSearchKeyword('');
                setPages([...pages, 'cases']);
              }}
              searchUsers={() => setPages([...pages, 'users'])}
              setOpen={setOpen}
            />
          )}
          {activePage === 'cases' && (
            <CommandMenuCases
              onSelect={handleCaseSelect}
              data={cases?.items ?? []}
              isLoading={isCasesLoading}
            />
          )}
          {activePage === 'users' && (
            <CommandMenuUsers onSelect={handleUserSelect} />
          )}
        </CommandList>
      </CommandDialog>
    </>
  );
}
