import { cn } from '@ui/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

const titleVariants = cva(
  'font-bold font-raleway leading-none tracking-tight',
  {
    variants: {
      variant: {
        default: 'text-2xl',
        large: 'text-4xl',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface ShellProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof titleVariants> {
  title: string;
  description?: string;
  headerAction?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export function DashboardShell(props: ShellProps) {
  return (
    <div className={props.className}>
      <div className="mb-4 flex items-center justify-between">
        <div className="space-y-1">
          <h1 className={cn(titleVariants({ variant: props.variant }))}>
            {props.title}
          </h1>
          {props.description && (
            <h2 className="text-base text-muted-foreground">
              {props.description}
            </h2>
          )}
        </div>
        {props.headerAction}
      </div>
      {props.children}
    </div>
  );
}
