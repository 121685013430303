import * as Lucide from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

export type Icon = LucideIcon;

export const Icons = {
  close: Lucide.X,
  spinner: Lucide.Loader2,
  chevronLeft: Lucide.ChevronLeft,
  chevronRight: Lucide.ChevronRight,
  chevronUpDown: Lucide.ChevronsUpDown,
  chevronDown: Lucide.ChevronDown,
  doubleChevronLeft: Lucide.ChevronsLeft,
  doubleChevronRight: Lucide.ChevronsRight,
  trash: Lucide.Trash,
  settings: Lucide.Settings,
  page: Lucide.File,
  more: Lucide.MoreVertical,
  moreHorizontal: Lucide.MoreHorizontal,
  add: Lucide.Plus,
  user: Lucide.User,
  users: Lucide.Users,
  rejectedUser: Lucide.UserX,
  sun: Lucide.SunMedium,
  moon: Lucide.Moon,
  clock: Lucide.Clock,
  check: Lucide.Check,
  checkCircle: Lucide.CheckCircle,
  circle: Lucide.Circle,
  circleDashed: Lucide.CircleDashed,
  xCircle: Lucide.XCircle,
  search: Lucide.Search,
  menu: Lucide.Menu,
  globe: Lucide.Globe,
  arrowDown: Lucide.ArrowDown,
  arrowUp: Lucide.ArrowUp,
  eyeOff: Lucide.EyeOff,
  plusCircle: Lucide.PlusCircle,
  settings2: Lucide.Settings2,
  laptop: Lucide.Laptop,
  info: Lucide.Info,
  wrench: Lucide.Wrench,
  building: Lucide.Building,
  calendar: Lucide.Calendar,
  export: Lucide.Download,
};
