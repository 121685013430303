"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCountryByAlpha3 = exports.getCountryByAlpha2 = exports.countries = void 0;
exports.countries = [
    {
        alfa2: 'AF',
        name: 'Afganistan',
        alfa3: 'AFG',
    },
    {
        alfa2: 'AX',
        name: 'Ahvenanmaa',
        alfa3: 'ALA',
    },
    {
        alfa2: 'NL',
        name: 'Alankomaat',
        alfa3: 'NLD',
    },
    {
        alfa2: 'AN',
        name: 'Alankomaiden Antillit',
        alfa3: 'ANT',
    },
    {
        alfa2: 'AL',
        name: 'Albania',
        alfa3: 'ALB',
    },
    {
        alfa2: 'DZ',
        name: 'Algeria',
        alfa3: 'DZA',
    },
    {
        alfa2: 'AS',
        name: 'Amerikan Samoa',
        alfa3: 'ASM',
    },
    {
        alfa2: 'AD',
        name: 'Andorra',
        alfa3: 'AND',
    },
    {
        alfa2: 'AO',
        name: 'Angola',
        alfa3: 'AGO',
    },
    {
        alfa2: 'AI',
        name: 'Anguilla',
        alfa3: 'AIA',
    },
    {
        alfa2: 'AQ',
        name: 'Antarktis',
        alfa3: 'ATA',
    },
    {
        alfa2: 'AG',
        name: 'Antigua ja Barbuda',
        alfa3: 'ATG',
    },
    {
        alfa2: 'AE',
        name: 'Arabiemiirikunnat',
        alfa3: 'ARE',
    },
    {
        alfa2: 'AR',
        name: 'Argentiina',
        alfa3: 'ARG',
    },
    {
        alfa2: 'AM',
        name: 'Armenia',
        alfa3: 'ARM',
    },
    {
        alfa2: 'AW',
        name: 'Aruba',
        alfa3: 'ABW',
    },
    {
        alfa2: 'AU',
        name: 'Australia',
        alfa3: 'AUS',
    },
    {
        alfa2: 'AZ',
        name: 'Azerbaidžan',
        alfa3: 'AZE',
    },
    {
        alfa2: 'BS',
        name: 'Bahama',
        alfa3: 'BHS',
    },
    {
        alfa2: 'BH',
        name: 'Bahrain',
        alfa3: 'BHR',
    },
    {
        alfa2: 'BD',
        name: 'Bangladesh',
        alfa3: 'BGD',
    },
    {
        alfa2: 'BB',
        name: 'Barbados',
        alfa3: 'BRB',
    },
    {
        alfa2: 'BE',
        name: 'Belgia',
        alfa3: 'BEL',
    },
    {
        alfa2: 'BZ',
        name: 'Belize',
        alfa3: 'BLZ',
    },
    {
        alfa2: 'BJ',
        name: 'Benin',
        alfa3: 'BEN',
    },
    {
        alfa2: 'BM',
        name: 'Bermuda',
        alfa3: 'BMU',
    },
    {
        alfa2: 'BT',
        name: 'Bhutan',
        alfa3: 'BTN',
    },
    {
        alfa2: 'BO',
        name: 'Bolivia',
        alfa3: 'BOL',
    },
    {
        alfa2: 'BA',
        name: 'Bosnia ja Hertsegovina',
        alfa3: 'BIH',
    },
    {
        alfa2: 'BW',
        name: 'Botswana',
        alfa3: 'BWA',
    },
    {
        alfa2: 'BV',
        name: 'Bouvet’nsaari',
        alfa3: 'BVT',
    },
    {
        alfa2: 'BR',
        name: 'Brasilia',
        alfa3: 'BRA',
    },
    {
        alfa2: 'IO',
        name: 'Brittiläinen Intian valtameren alue',
        alfa3: 'IOT',
    },
    {
        alfa2: 'VG',
        name: 'Brittiläiset Neitsytsaaret',
        alfa3: 'VGB',
    },
    {
        alfa2: 'BN',
        name: 'Brunei',
        alfa3: 'BRN',
    },
    {
        alfa2: 'BG',
        name: 'Bulgaria',
        alfa3: 'BGR',
    },
    {
        alfa2: 'BF',
        name: 'Burkina Faso',
        alfa3: 'BFA',
    },
    {
        alfa2: 'BI',
        name: 'Burundi',
        alfa3: 'BDI',
    },
    {
        alfa2: 'KY',
        name: 'Caymansaaret',
        alfa3: 'CYM',
    },
    {
        alfa2: 'CL',
        name: 'Chile',
        alfa3: 'CHL',
    },
    {
        alfa2: 'CK',
        name: 'Cookinsaaret',
        alfa3: 'COK',
    },
    {
        alfa2: 'CR',
        name: 'Costa Rica',
        alfa3: 'CRI',
    },
    {
        alfa2: 'DG',
        name: 'Diego Garcia',
        alfa3: '',
    },
    {
        alfa2: 'DJ',
        name: 'Djibouti',
        alfa3: 'DJI',
    },
    {
        alfa2: 'DM',
        name: 'Dominica',
        alfa3: 'DMA',
    },
    {
        alfa2: 'DO',
        name: 'Dominikaaninen tasavalta',
        alfa3: 'DOM',
    },
    {
        alfa2: 'EC',
        name: 'Ecuador',
        alfa3: 'ECU',
    },
    {
        alfa2: 'EG',
        name: 'Egypti',
        alfa3: 'EGY',
    },
    {
        alfa2: 'SV',
        name: 'El Salvador',
        alfa3: 'SLV',
    },
    {
        alfa2: 'ER',
        name: 'Eritrea',
        alfa3: 'ERI',
    },
    {
        alfa2: 'ES',
        name: 'Espanja',
        alfa3: 'ESP',
    },
    {
        alfa2: 'ET',
        name: 'Etiopia',
        alfa3: 'ETH',
    },
    {
        alfa2: 'ZA',
        name: 'Etelä-Afrikka',
        alfa3: 'ZAF',
    },
    {
        alfa2: 'GS',
        name: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
        alfa3: 'SGS',
    },
    {
        alfa2: 'FK',
        name: 'Falklandinsaaret',
        alfa3: 'FLK',
    },
    {
        alfa2: 'FO',
        name: 'Färsaaret',
        alfa3: 'FRO',
    },
    {
        alfa2: 'FJ',
        name: 'Fidži',
        alfa3: 'FJI',
    },
    {
        alfa2: 'PH',
        name: 'Filippiinit',
        alfa3: 'PHL',
    },
    {
        alfa2: 'GA',
        name: 'Gabon',
        alfa3: 'GAB',
    },
    {
        alfa2: 'GM',
        name: 'Gambia',
        alfa3: 'GMB',
    },
    {
        alfa2: 'GE',
        name: 'Georgia',
        alfa3: 'GEO',
    },
    {
        alfa2: 'GH',
        name: 'Ghana',
        alfa3: 'GHA',
    },
    {
        alfa2: 'GI',
        name: 'Gibraltar',
        alfa3: 'GIB',
    },
    {
        alfa2: 'GD',
        name: 'Grenada',
        alfa3: 'GRD',
    },
    {
        alfa2: 'GL',
        name: 'Grönlanti',
        alfa3: 'GRL',
    },
    {
        alfa2: 'GP',
        name: 'Guadeloupe',
        alfa3: 'GLP',
    },
    {
        alfa2: 'GU',
        name: 'Guam',
        alfa3: 'GUM',
    },
    {
        alfa2: 'GT',
        name: 'Guatemala',
        alfa3: 'GTM',
    },
    {
        alfa2: 'GG',
        name: 'Guernsey',
        alfa3: 'GGY',
    },
    {
        alfa2: 'GN',
        name: 'Guinea',
        alfa3: 'GIN',
    },
    {
        alfa2: 'GW',
        name: 'Guinea-Bissau',
        alfa3: 'GNB',
    },
    {
        alfa2: 'GY',
        name: 'Guyana',
        alfa3: 'GUY',
    },
    {
        alfa2: 'HT',
        name: 'Haiti',
        alfa3: 'HTI',
    },
    {
        alfa2: 'HM',
        name: 'Heard ja McDonaldinsaaret',
        alfa3: 'HMD',
    },
    {
        alfa2: 'HN',
        name: 'Honduras',
        alfa3: 'HND',
    },
    {
        alfa2: 'HK',
        name: 'Hongkong',
        alfa3: 'HKG',
    },
    {
        alfa2: 'ID',
        name: 'Indonesia',
        alfa3: 'IDN',
    },
    {
        alfa2: 'IN',
        name: 'Intia',
        alfa3: 'IND',
    },
    {
        alfa2: 'IQ',
        name: 'Irak',
        alfa3: 'IRQ',
    },
    {
        alfa2: 'IR',
        name: 'Iran',
        alfa3: 'IRN',
    },
    {
        alfa2: 'IE',
        name: 'Irlanti',
        alfa3: 'IRL',
    },
    {
        alfa2: 'IS',
        name: 'Islanti',
        alfa3: 'ISL',
    },
    {
        alfa2: 'IL',
        name: 'Israel',
        alfa3: 'ISR',
    },
    {
        alfa2: 'IT',
        name: 'Italia',
        alfa3: 'ITA',
    },
    {
        alfa2: 'TL',
        name: 'Itä-Timor',
        alfa3: 'TLS',
    },
    {
        alfa2: 'AT',
        name: 'Itävalta',
        alfa3: 'AUT',
    },
    {
        alfa2: 'JM',
        name: 'Jamaika',
        alfa3: 'JAM',
    },
    {
        alfa2: 'JP',
        name: 'Japani',
        alfa3: 'JPN',
    },
    {
        alfa2: 'YE',
        name: 'Jemen',
        alfa3: 'YEM',
    },
    {
        alfa2: 'JE',
        name: 'Jersey',
        alfa3: 'JEY',
    },
    {
        alfa2: 'JO',
        name: 'Jordania',
        alfa3: 'JOR',
    },
    {
        alfa2: 'CX',
        name: 'Joulusaari',
        alfa3: 'CXR',
    },
    {
        alfa2: 'KH',
        name: 'Kambodža',
        alfa3: 'KHM',
    },
    {
        alfa2: 'CM',
        name: 'Kamerun',
        alfa3: 'CMR',
    },
    {
        alfa2: 'CA',
        name: 'Kanada',
        alfa3: 'CAN',
    },
    {
        alfa2: 'IC',
        name: 'Kanariansaaret',
        alfa3: 'IC',
    },
    {
        alfa2: 'CV',
        name: 'Kap Verde',
        alfa3: 'CPV',
    },
    {
        alfa2: 'KZ',
        name: 'Kazakstan',
        alfa3: 'KAZ',
    },
    {
        alfa2: 'KE',
        name: 'Kenia',
        alfa3: 'KEN',
    },
    {
        alfa2: 'CF',
        name: 'Keski-Afrikan tasavalta',
        alfa3: 'CAF',
    },
    {
        alfa2: 'CN',
        name: 'Kiina',
        alfa3: 'CHN',
    },
    {
        alfa2: 'KG',
        name: 'Kirgisia',
        alfa3: 'KGZ',
    },
    {
        alfa2: 'KI',
        name: 'Kiribati',
        alfa3: 'KIR',
    },
    {
        alfa2: 'CO',
        name: 'Kolumbia',
        alfa3: 'COL',
    },
    {
        alfa2: 'KM',
        name: 'Komorit',
        alfa3: 'COM',
    },
    {
        alfa2: 'CD',
        name: 'Kongon demokraattinen tasavalta',
        alfa3: 'COD',
    },
    {
        alfa2: 'CG',
        name: 'Kongon tasavalta',
        alfa3: 'COG',
    },
    {
        alfa2: 'CC',
        name: 'Kookossaaret',
        alfa3: 'CCK',
    },
    {
        alfa2: 'KP',
        name: 'Korean demokraattinen kansantasavalta',
        alfa3: 'PRK',
    },
    {
        alfa2: 'KR',
        name: 'Korean tasavalta',
        alfa3: 'KOR',
    },
    {
        alfa2: 'GR',
        name: 'Kreikka',
        alfa3: 'GRC',
    },
    {
        alfa2: 'HR',
        name: 'Kroatia',
        alfa3: 'HRV',
    },
    {
        alfa2: 'CU',
        name: 'Kuuba',
        alfa3: 'CUB',
    },
    {
        alfa2: 'KW',
        name: 'Kuwait',
        alfa3: 'KWT',
    },
    {
        alfa2: 'CY',
        name: 'Kypros',
        alfa3: 'CYP',
    },
    {
        alfa2: 'LA',
        name: 'Laos',
        alfa3: 'LAO',
    },
    {
        alfa2: 'LV',
        name: 'Latvia',
        alfa3: 'LVA',
    },
    {
        alfa2: 'LS',
        name: 'Lesotho',
        alfa3: 'LSO',
    },
    {
        alfa2: 'LB',
        name: 'Libanon',
        alfa3: 'LBN',
    },
    {
        alfa2: 'LR',
        name: 'Liberia',
        alfa3: 'LBR',
    },
    {
        alfa2: 'LY',
        name: 'Libya',
        alfa3: 'LBY',
    },
    {
        alfa2: 'LI',
        name: 'Liechtenstein',
        alfa3: 'LIE',
    },
    {
        alfa2: 'LT',
        name: 'Liettua',
        alfa3: 'LTU',
    },
    {
        alfa2: 'LU',
        name: 'Luxemburg',
        alfa3: 'LUX',
    },
    {
        alfa2: 'EH',
        name: 'Länsi-Sahara',
        alfa3: 'ESH',
    },
    {
        alfa2: 'MO',
        name: 'Macao',
        alfa3: 'MAC',
    },
    {
        alfa2: 'MG',
        name: 'Madagaskar',
        alfa3: 'MDG',
    },
    {
        alfa2: 'MK',
        name: 'Makedonia',
        alfa3: 'MKD',
    },
    {
        alfa2: 'MW',
        name: 'Malawi',
        alfa3: 'MWI',
    },
    {
        alfa2: 'MV',
        name: 'Malediivit',
        alfa3: 'MDV',
    },
    {
        alfa2: 'MY',
        name: 'Malesia',
        alfa3: 'MYS',
    },
    {
        alfa2: 'ML',
        name: 'Mali',
        alfa3: 'MLI',
    },
    {
        alfa2: 'MT',
        name: 'Malta',
        alfa3: 'MLT',
    },
    {
        alfa2: 'IM',
        name: 'Mansaari',
        alfa3: 'IMN',
    },
    {
        alfa2: 'MA',
        name: 'Marokko',
        alfa3: 'MAR',
    },
    {
        alfa2: 'MH',
        name: 'Marshallinsaaret',
        alfa3: 'MHL',
    },
    {
        alfa2: 'MQ',
        name: 'Martinique',
        alfa3: 'MTQ',
    },
    {
        alfa2: 'MR',
        name: 'Mauritania',
        alfa3: 'MRT',
    },
    {
        alfa2: 'MU',
        name: 'Mauritius',
        alfa3: 'MUS',
    },
    {
        alfa2: 'YT',
        name: 'Mayotte',
        alfa3: 'MYT',
    },
    {
        alfa2: 'MX',
        name: 'Meksiko',
        alfa3: 'MEX',
    },
    {
        alfa2: 'FM',
        name: 'Mikronesian liittovaltio',
        alfa3: 'FSM',
    },
    {
        alfa2: 'MD',
        name: 'Moldova',
        alfa3: 'MDA',
    },
    {
        alfa2: 'MC',
        name: 'Monaco',
        alfa3: 'MCO',
    },
    {
        alfa2: 'MN',
        name: 'Mongolia',
        alfa3: 'MNG',
    },
    {
        alfa2: 'ME',
        name: 'Montenegro',
        alfa3: 'MNE',
    },
    {
        alfa2: 'MS',
        name: 'Montserrat',
        alfa3: 'MSR',
    },
    {
        alfa2: 'MZ',
        name: 'Mosambik',
        alfa3: 'MOZ',
    },
    {
        alfa2: 'MM',
        name: 'Myanmar',
        alfa3: 'MMR',
    },
    {
        alfa2: 'NA',
        name: 'Namibia',
        alfa3: 'NAM',
    },
    {
        alfa2: 'NR',
        name: 'Nauru',
        alfa3: 'NRU',
    },
    {
        alfa2: 'NP',
        name: 'Nepal',
        alfa3: 'NPL',
    },
    {
        alfa2: 'NI',
        name: 'Nicaragua',
        alfa3: 'NIC',
    },
    {
        alfa2: 'NE',
        name: 'Niger',
        alfa3: 'NER',
    },
    {
        alfa2: 'NG',
        name: 'Nigeria',
        alfa3: 'NGA',
    },
    {
        alfa2: 'NU',
        name: 'Niue',
        alfa3: 'NIU',
    },
    {
        alfa2: 'NF',
        name: 'Norfolkinsaari',
        alfa3: 'NFK',
    },
    {
        alfa2: 'NO',
        name: 'Norja',
        alfa3: 'NOR',
    },
    {
        alfa2: 'CI',
        name: 'Norsunluurannikko',
        alfa3: 'CIV',
    },
    {
        alfa2: 'OM',
        name: 'Oman',
        alfa3: 'OMN',
    },
    {
        alfa2: 'PK',
        name: 'Pakistan',
        alfa3: 'PAK',
    },
    {
        alfa2: 'PW',
        name: 'Palau',
        alfa3: 'PLW',
    },
    {
        alfa2: 'PS',
        name: 'Palestiina',
        alfa3: 'PSE',
    },
    {
        alfa2: 'PA',
        name: 'Panama',
        alfa3: 'PAN',
    },
    {
        alfa2: 'PG',
        name: 'Papua-Uusi-Guinea',
        alfa3: 'PNG',
    },
    {
        alfa2: 'PY',
        name: 'Paraguay',
        alfa3: 'PRY',
    },
    {
        alfa2: 'PE',
        name: 'Peru',
        alfa3: 'PER',
    },
    {
        alfa2: 'MP',
        name: 'Pohjois-Mariaanit',
        alfa3: 'MNP',
    },
    {
        alfa2: 'PN',
        name: 'Pitcairn',
        alfa3: 'PCN',
    },
    {
        alfa2: 'PT',
        name: 'Portugali',
        alfa3: 'PRT',
    },
    {
        alfa2: 'PR',
        name: 'Puerto Rico',
        alfa3: 'PRI',
    },
    {
        alfa2: 'PL',
        name: 'Puola',
        alfa3: 'POL',
    },
    {
        alfa2: 'GQ',
        name: 'Päiväntasaajan Guinea',
        alfa3: 'GNQ',
    },
    {
        alfa2: 'QA',
        name: 'Qatar',
        alfa3: 'QAT',
    },
    {
        alfa2: 'FR',
        name: 'Ranska',
        alfa3: 'FRA',
    },
    {
        alfa2: 'TF',
        name: 'Ranskan eteläiset alueet',
        alfa3: 'ATF',
    },
    {
        alfa2: 'GF',
        name: 'Ranskan Guayana',
        alfa3: 'GUF',
    },
    {
        alfa2: 'PF',
        name: 'Ranskan Polynesia',
        alfa3: 'PYF',
    },
    {
        alfa2: 'RE',
        name: 'Réunion',
        alfa3: 'REU',
    },
    {
        alfa2: 'RO',
        name: 'Romania',
        alfa3: 'ROU',
    },
    {
        alfa2: 'RW',
        name: 'Ruanda',
        alfa3: 'RWA',
    },
    {
        alfa2: 'SE',
        name: 'Ruotsi',
        alfa3: 'SWE',
    },
    {
        alfa2: 'SH',
        name: 'Saint Helena',
        alfa3: 'SHN',
    },
    {
        alfa2: 'KN',
        name: 'Saint Kitts ja Nevis',
        alfa3: 'KNA',
    },
    {
        alfa2: 'LC',
        name: 'Saint Lucia',
        alfa3: 'LCA',
    },
    {
        alfa2: 'PM',
        name: 'Saint-Pierre ja Miquelon',
        alfa3: 'SPM',
    },
    {
        alfa2: 'VC',
        name: 'Saint Vincent ja Grenadiinit',
        alfa3: 'VCT',
    },
    {
        alfa2: 'DE',
        name: 'Saksa',
        alfa3: 'DEU',
    },
    {
        alfa2: 'SB',
        name: 'Salomonsaaret',
        alfa3: 'SLB',
    },
    {
        alfa2: 'ZM',
        name: 'Sambia',
        alfa3: 'ZMB',
    },
    {
        alfa2: 'WS',
        name: 'Samoa',
        alfa3: 'WSM',
    },
    {
        alfa2: 'SM',
        name: 'San Marino',
        alfa3: 'SMR',
    },
    {
        alfa2: 'ST',
        name: 'São Tomé ja Príncipe',
        alfa3: 'STP',
    },
    {
        alfa2: 'SA',
        name: 'Saudi-Arabia',
        alfa3: 'SAU',
    },
    {
        alfa2: 'SN',
        name: 'Senegal',
        alfa3: 'SEN',
    },
    {
        alfa2: 'RS',
        name: 'Serbia',
        alfa3: 'SRB',
    },
    {
        alfa2: 'SC',
        name: 'Seychellit',
        alfa3: 'SYC',
    },
    {
        alfa2: 'SL',
        name: 'Sierra Leone',
        alfa3: 'SLE',
    },
    {
        alfa2: 'SG',
        name: 'Singapore',
        alfa3: 'SGP',
    },
    {
        alfa2: 'SK',
        name: 'Slovakia',
        alfa3: 'SVK',
    },
    {
        alfa2: 'SI',
        name: 'Slovenia',
        alfa3: 'SVN',
    },
    {
        alfa2: 'SO',
        name: 'Somalia',
        alfa3: 'SOM',
    },
    {
        alfa2: 'LK',
        name: 'Sri Lanka',
        alfa3: 'LKA',
    },
    {
        alfa2: 'SD',
        name: 'Sudan',
        alfa3: 'SDN',
    },
    {
        alfa2: 'FI',
        name: 'Suomi',
        alfa3: 'FIN',
    },
    {
        alfa2: 'SR',
        name: 'Suriname',
        alfa3: 'SUR',
    },
    {
        alfa2: 'SJ',
        name: 'Svalbard ja Jan Mayen',
        alfa3: 'SJM',
    },
    {
        alfa2: 'SZ',
        name: 'Swazimaa',
        alfa3: 'SWZ',
    },
    {
        alfa2: 'CH',
        name: 'Sveitsi',
        alfa3: 'CHE',
    },
    {
        alfa2: 'SY',
        name: 'Syyria',
        alfa3: 'SYR',
    },
    {
        alfa2: 'TJ',
        name: 'Tadžikistan',
        alfa3: 'TJK',
    },
    {
        alfa2: 'TW',
        name: 'Taiwan',
        alfa3: 'TWN',
    },
    {
        alfa2: 'TZ',
        name: 'Tansania',
        alfa3: 'TZA',
    },
    {
        alfa2: 'DK',
        name: 'Tanska',
        alfa3: 'DNK',
    },
    {
        alfa2: 'TH',
        name: 'Thaimaa',
        alfa3: 'THA',
    },
    {
        alfa2: 'TG',
        name: 'Togo',
        alfa3: 'TGO',
    },
    {
        alfa2: 'TK',
        name: 'Tokelau',
        alfa3: 'TKL',
    },
    {
        alfa2: 'TO',
        name: 'Tonga',
        alfa3: 'TON',
    },
    {
        alfa2: 'TT',
        name: 'Trinidad ja Tobago',
        alfa3: 'TTO',
    },
    {
        alfa2: 'TD',
        name: 'Tšad',
        alfa3: 'TCD',
    },
    {
        alfa2: 'CZ',
        name: 'Tšekki',
        alfa3: 'CZE',
    },
    {
        alfa2: 'TN',
        name: 'Tunisia',
        alfa3: 'TUN',
    },
    {
        alfa2: 'TR',
        name: 'Turkki',
        alfa3: 'TUR',
    },
    {
        alfa2: 'TM',
        name: 'Turkmenistan',
        alfa3: 'TKM',
    },
    {
        alfa2: 'TC',
        name: 'Turks- ja Caicossaaret',
        alfa3: 'TCA',
    },
    {
        alfa2: 'TV',
        name: 'Tuvalu',
        alfa3: 'TUV',
    },
    {
        alfa2: 'UG',
        name: 'Uganda',
        alfa3: 'UGA',
    },
    {
        alfa2: 'UA',
        name: 'Ukraina',
        alfa3: 'UKR',
    },
    {
        alfa2: 'HU',
        name: 'Unkari',
        alfa3: 'HUN',
    },
    {
        alfa2: 'UY',
        name: 'Uruguay',
        alfa3: 'URY',
    },
    {
        alfa2: 'NC',
        name: 'Uusi-Kaledonia',
        alfa3: 'NCL',
    },
    {
        alfa2: 'NZ',
        name: 'Uusi-Seelanti',
        alfa3: 'NZL',
    },
    {
        alfa2: 'UZ',
        name: 'Uzbekistan',
        alfa3: 'UZB',
    },
    {
        alfa2: 'BY',
        name: 'Valko-Venäjä',
        alfa3: 'BLR',
    },
    {
        alfa2: 'VU',
        name: 'Vanuatu',
        alfa3: 'VUT',
    },
    {
        alfa2: 'VA',
        name: 'Vatikaanivaltio',
        alfa3: 'VAT',
    },
    {
        alfa2: 'VE',
        name: 'Venezuela',
        alfa3: 'VEN',
    },
    {
        alfa2: 'RU',
        name: 'Venäjä',
        alfa3: 'RUS',
    },
    {
        alfa2: 'VN',
        name: 'Vietnam',
        alfa3: 'VNM',
    },
    {
        alfa2: 'EE',
        name: 'Viro',
        alfa3: 'EST',
    },
    {
        alfa2: 'WF',
        name: 'Wallis ja Futunasaaret',
        alfa3: 'WLF',
    },
    {
        alfa2: 'GB',
        name: 'Yhdistynyt kuningaskunta',
        alfa3: 'GBR',
    },
    {
        alfa2: 'US',
        name: 'Yhdysvallat',
        alfa3: 'USA',
    },
    {
        alfa2: 'VI',
        name: 'Yhdysvaltain Neitsytsaaret',
        alfa3: 'VIR',
    },
    {
        alfa2: 'UM',
        name: 'Yhdysvaltain pienet erillissaaret',
        alfa3: 'UMI',
    },
    {
        alfa2: 'ZW',
        name: 'Zimbabwe',
        alfa3: 'ZWE',
    },
    {
        alfa2: 'XB',
        name: 'XBorderTest',
        alfa3: 'SXB',
    },
    {
        alfa2: 'XK',
        name: 'Kosovo',
        alfa3: 'XKX',
    },
];
function getCountryByAlpha2(alfa2) {
    if (alfa2) {
        alfa2 = alfa2.toUpperCase();
        const country = exports.countries.find((c) => c.alfa2 === alfa2);
        if (country) {
            return {
                name: country.name,
                alfa2: country.alfa2,
                alfa3: country.alfa3,
            };
        }
    }
}
exports.getCountryByAlpha2 = getCountryByAlpha2;
function getCountryByAlpha3(alfa3) {
    if (alfa3) {
        alfa3 = alfa3.toUpperCase();
        const country = exports.countries.find((c) => c.alfa3 === alfa3);
        if (country) {
            return {
                name: country.name,
                alfa2: country.alfa2,
                alfa3: country.alfa3,
            };
        }
    }
}
exports.getCountryByAlpha3 = getCountryByAlpha3;
