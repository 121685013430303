import { MobileNewCaseNav } from './mobile-new-case-nav';
import { NewCaseNav } from './new-case-nav';

export function NewCaseHeader() {
  return (
    <header className="md:sticky top-0 z-40 w-full max-w-[532px] md:max-w-none self-center px-4">
      <div className="h-18 md:container max-w-none flex items-center justify-between py-4 ">
        <NewCaseNav />
        <MobileNewCaseNav />
      </div>
    </header>
  );
}
