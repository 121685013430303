import { LanguageToggle } from './language-toggle';
import { ThemeToggle } from './theme-toggle';

export function SiteFooter() {
  return (
    <footer className="py-6 md:py-0 border-t bg-background-secondary">
      <div className="container max-w-none flex items-center justify-between gap-4 md:h-24">
        <p className="text-center text-sm leading-loose text-muted-foreground md:text-left">
          {/* here is a placeholder for text if it's wanted to add */}
        </p>
        <div className="flex items-center gap-2">
          <ThemeToggle />
          <LanguageToggle />
        </div>
      </div>
    </footer>
  );
}
