"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workInstructionLabelMap = void 0;
exports.workInstructionLabelMap = {
    isEnabled: 'Näytä tämä työohje',
    description: 'Kuvaus',
    serviceProviderInfo: 'Vasteenvalintaohje',
    validity: 'Voimassaolo',
    otherDescription: 'Muuta',
    name: 'Nimi',
    serviceChannels: 'Palvelukanavat',
    serviceNumber: 'Palvelunumero',
    serviceStartDate: 'Alkamispvm',
    serviceEndDate: 'Päättymispvm',
    serviceUpdatedDate: 'Päivityspvm',
    maxVehicleMass: 'Max kokonaismassa',
    maxExpenses: 'Max kustannukset',
    contractDetails: 'Sopimuksen tiedot',
    specificCoverage: 'Yksityiskohdat',
    'contractDetails.serviceProviders': 'Oikeutetut vasteet',
    'contractDetails.invoicing': 'Laskutus',
    'contractDetails.serviceValidity': 'Ajoneuvon tunnistus, palvelun voimassaolon tarkistus',
    'contractDetails.serviceCoverage': 'Palvelun kattamat tilanteet yleisesti',
    'contractDetails.replacementCar': 'Sijaisauto',
    'contractDetails.accommodation': 'Majoitus',
    'contractDetails.invoicing.invoiceToRepairShop': 'Lasku korjaamolle',
    'contractDetails.invoicing.invoiceToAl': 'Lasku meille',
    'contractDetails.invoicing.alContract': 'AL-sopimus',
    'contractDetails.invoicing.arcContract': 'ARC-sopimus',
    'contractDetails.invoicing.sendReportToRepairShop': 'Raportin lähetys korjaamolle',
    'contractDetails.serviceValidity.serviceValidity': 'Ajoneuvon tunnistus, palvelun voimassaolon tarkistus',
    'contractDetails.serviceValidity.fddsVin': 'FDDS VIN',
    'contractDetails.serviceValidity.customerRegister': 'Asiakasrekisteri',
    'contractDetails.serviceValidity.alAssistance': 'AL Assistance -järjestelmä',
    'contractDetails.serviceValidity.maintenanceRequirement': 'Huoltovaatimus',
    'contractDetails.serviceValidity.registeredToFinland': 'Rekisteröity Suomeen',
    'contractDetails.serviceValidity.validInAllArcCountries': 'Voimassaoloalue kaikki ARC-maat',
    'contractDetails.serviceCoverage.technicalAccidental': 'Tekninen vika',
    'contractDetails.serviceCoverage.accident': 'Liikennevahinko',
    'contractDetails.serviceCoverage.technicalSelfInflicted': 'Tekninen vika (itseaiheutettu)',
    'contractDetails.serviceCoverage.fire': 'Tulipalo',
    'contractDetails.serviceCoverage.vandalism': 'Ilkivalta',
    'contractDetails.serviceCoverage.theft': 'Varkaus',
    'contractDetails.serviceCoverage.appliesToTrailer': 'Koskee perävaunua',
    'contractDetails.serviceProviders.roadService': 'Tiepalvelu',
    'contractDetails.serviceProviders.towing': 'Hinaus',
    'contractDetails.serviceProviders.replacementCar': 'Sijaisauto',
    'contractDetails.replacementCar.grantedImmediately': 'Myönnetään heti',
    'contractDetails.replacementCar.limitedDistanceFromHome': 'Raj. matka asuinpaikasta',
    'contractDetails.replacementCar.requiresTowing': 'Vaaditaan hinaus',
    'contractDetails.replacementCar.maxSize': 'Raj. kokoluokka',
    'contractDetails.replacementCar.equilevantToCustomerVehicle': 'Vastaava kuin asiakkaalla',
    'contractDetails.replacementCar.maxLength': 'Raj. kesto',
    'contractDetails.replacementCar.pickup': 'Nouto',
    'contractDetails.replacementCar.depositFee': 'Jättömaksu',
    'contractDetails.replacementCar.deliveryFee': 'Toimitusmaksu',
    'contractDetails.replacementCar.hitch': 'Vetokoukku',
    'contractDetails.replacementCar.specialCosts': 'Erityiskustannukset',
    'contractDetails.replacementCar.canBeCombinedWithOtherServices': 'Yhdistettävissä muiden palveluiden kanssa',
    'contractDetails.accommodation.limitedDistanceFromHome': 'Raj. matka asuinpaikasta',
    'contractDetails.accommodation.canBeCombinedWithOtherServices': 'Yhdistettävissä muiden palveluiden kanssa',
    'contractDetails.accommodation.accommodation': 'Majoitus',
    'contractDetails.accommodation.limitedAmountOfNights': 'Raj. yömäärä',
    'contractDetails.accommodation.limitedCost': 'Raj. kustannus',
    'contractDetails.accommodation.breakfast': 'Aamiainen',
    'contractDetails.customerTransport': 'Asiakkaan kuljetus',
    'contractDetails.customerTransport.taxi': 'Taksi',
    'contractDetails.customerTransport.trainOrBus': 'Juna/linja-auto',
    'contractDetails.customerTransport.otherVehicle': 'Muu kulkuneuvo',
    'contractDetails.customerTransport.airplane': 'Lentokone',
    'contractDetails.customerTransport.canBeCombinedWithOtherServices': 'Yhdistettävissä muiden palveluiden kanssa',
    'contractDetails.vehicleStorageAndReturn': 'Auton varastointi ja palautus',
    'contractDetails.vehicleStorageAndReturn.vehicleStorage': 'Auton varastointi',
    'contractDetails.vehicleStorageAndReturn.returnFixedVehicle': 'Korjatun auton palautus',
    'contractDetails.vehicleStorageAndReturn.returnFixedVehicleToHome': 'Korjatun auton palautus kotiin',
    'contractDetails.vehicleStorageAndReturn.returnFixedVehicleToNearestDealer': 'Korjatun auton palautus lähimmälle jälleenmyyjälle',
    'contractDetails.vehicleStorageAndReturn.customerPicksUpFixedVehicle': 'Asiakas noutaa korjatun auton',
    'contractDetails.vehicleStorageAndReturn.returnBrokenVehicle': 'Rikkinäisen auton palautus',
    'specificCoverage.equipmentFailure': 'Laiteviat',
    'specificCoverage.safetyEquipment': 'Turvalaitteet',
    'specificCoverage.technicalProblem': 'Tekninen vika ja itseaiheutettu',
    'specificCoverage.technicalProblem.batteryDrained': 'Akku, tyhjentynyt',
    'specificCoverage.technicalProblem.fuelFrozen': 'Polttoaine, jäätynyt',
    'specificCoverage.technicalProblem.fuelContaminated': 'Polttoaine, saastunut',
    'specificCoverage.technicalProblem.outOfFuel': 'Polttoaine, loppunut',
    'specificCoverage.technicalProblem.wrongFuel': 'Polttoaine, väärä tankkaus',
    'specificCoverage.technicalProblem.keyBroken': 'Avain, rikkoutunut',
    'specificCoverage.technicalProblem.keyBrokenInIgnitionSwitch': 'Avain, rikkoutunut virtalukkoon',
    'specificCoverage.technicalProblem.keyDoesNotGoIntoIgnition': 'Avain ei mene virtalukkoon',
    'specificCoverage.technicalProblem.electricalKey': 'Elektroninen avain',
    'specificCoverage.technicalProblem.keyInsideLockedCar': 'Avain, sisällä lukitussa autossa',
    'specificCoverage.technicalProblem.keyLost': 'Avain, kadonnut',
    'specificCoverage.technicalProblem.keyStolen': 'Avain, varastettu',
    'specificCoverage.technicalProblem.remoteDoesNotWork': 'Kaukosäädin ei toimi',
    'specificCoverage.technicalProblem.lockDoesNotWork': 'Lukko ei toimi',
    'specificCoverage.technicalProblem.lockBlocked': 'Lukko tukkiutunut',
    'specificCoverage.technicalProblem.wrongKeyInLock': 'Väärä avain lukossa',
    'specificCoverage.technicalProblem.flatTyre': 'Rengasrikko',
    'specificCoverage.technicalProblem.breaks': 'Jarrut',
    'specificCoverage.equipmentFailure.airConditioning': 'Ilmastointi, ohjaamon jäähdytys',
    'specificCoverage.equipmentFailure.burglarAlarm': 'Murtohälytin',
    'specificCoverage.equipmentFailure.burglarAlarmPreventDriving': 'Murtohälytin, ajonestolaite, estää ajamisen',
    'specificCoverage.equipmentFailure.windshieldOrRearWindowBrokenNotVandalism': 'Tuulilasi, takalasi rikkoutunut, muu kuin vandalismi',
    'specificCoverage.equipmentFailure.convertibleRoofDoesNotWork': 'Avoauton katto ei toimi',
    'specificCoverage.equipmentFailure.doorDoesNotClose': 'Ovi ei mene kiinni',
    'specificCoverage.equipmentFailure.driverSeatStuckCannotDrive': 'Kuskin penkki jumiutunut, ei pysty ajamaan',
    'specificCoverage.equipmentFailure.immobilizer': 'Ajonestolaite',
    'specificCoverage.equipmentFailure.equipmentDoesNotWork': 'Laite ei toimi',
    'specificCoverage.equipmentFailure.gearboxStuck': 'Lukkiutunut vaihdelaatikko',
    'specificCoverage.equipmentFailure.problemWithProducts': 'Ongelmat tuotteiden kanssa',
    'specificCoverage.equipmentFailure.sideGlassBrokenOrWontClose': 'Sivulasi rikkoutunut tai ei mene kiinni',
    'specificCoverage.equipmentFailure.stuckOrFrozenChargingPort': 'Jumiutunut/jäätynyt latausluukku',
    'specificCoverage.equipmentFailure.stuckOrFrozenChargingCable': 'Jumiutunut/jäätynyt latauskaapeli',
    'specificCoverage.safetyEquipment.blinkers': 'Suuntavilkut',
    'specificCoverage.safetyEquipment.enginePowerDecreaseOrLoss': 'Moottorin tehon lasku, menetys',
    'specificCoverage.safetyEquipment.statutoryLights': 'Lakisääteiset valot',
    'specificCoverage.safetyEquipment.otherLights': 'Valot, muut',
    'specificCoverage.safetyEquipment.powerSteering': 'Ohjaustehostin',
    'specificCoverage.safetyEquipment.driversSeatBelt': 'Turvavyö, kuljettajan',
    'specificCoverage.safetyEquipment.passengersSeatBelt': 'Turvavyö, matkustajien',
    'specificCoverage.safetyEquipment.airbagWarningLight': 'Turvatyynyn häiriövalo (airbag)',
    'specificCoverage.safetyEquipment.windshieldWipers': 'Tuulilasin pyyhkimet',
};
