"use strict";
/*
ViewModels that are formed server side.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.inspectorServiceStatusLabel = void 0;
exports.inspectorServiceStatusLabel = {
    accepted: 'Kuitattu',
    arrived: 'Paikalla',
    arrivedEstimate: 'Paikalla (arvio)',
    canceled: 'Peruttu',
    noReply: 'Ei vastausta',
    pending: 'Odottaa',
    ready: 'Valmis',
    readyEstimate: 'Valmis (arvio)',
    rejected: 'Kieltäydytty',
    requested: 'Pyydetty',
};
