import { SideBarNav } from '@/components/layout/sidebar-nav';
import { SiteFooter } from '@/components/layout/site-footer';
import { SiteHeader } from '@/components/layout/site-header';
import { ScrollArea } from '@ui/components/scroll-area';
import { workspaceConfig } from '@/config/dashboard';
import { useLocale } from '@/hooks/use-language';
import { namespaces } from '@/i18n/settings';
import * as React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export function DashboardLayout() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  // this is a hack to preload all namespaces
  // at once to avoid flickering when switching pages
  const { lang } = useLocale(namespaces);

  React.useEffect(() => {
    if (params.lang !== lang) {
      const path = location.pathname.split('/');
      const [, , ...pathEnd] = path;
      navigate(`/${lang}/${pathEnd.join('/')}`);
    }
  }, [lang, location, navigate, params.lang]);

  return (
    <div className="relative flex min-h-dvh flex-col">
      <div className="flex-1 z-10 relative">
        <SiteHeader />
        <div className="flex-1 items-start md:grid md:grid-cols-[275px_minmax(0,1fr)] bg-background">
          <aside className="fixed top-[4rem] max-h-[calc(100vh-4rem)] z-30 hidden h-full w-full shrink-0 overflow-y-auto md:sticky md:block border-r bg-background-secondary">
            <ScrollArea>
              <SideBarNav items={workspaceConfig.sidebarNav} />
            </ScrollArea>
          </aside>
          <div className="flex justify-center">
            {/* 4.2rem = height of header bar, 6rem height of footer */}
            <main className="py-6 container px-4 md:px-8 min-h-[calc(100vh-4.2rem-6rem)]">
              <Outlet />
            </main>
          </div>
        </div>
        <SiteFooter />
      </div>
    </div>
  );
}
