import * as React from 'react';
import { DashboardShell } from '@/components/layout/shell';
import { Form } from '@ui/components/form';
import { useLocale } from '@/hooks/use-language';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUser } from '@/hooks/use-auth';
import { CaseSchema, DriveTrain } from '@/backend/models/Case';
import { trpc } from '@/lib/trpc';
import { Separator } from '@ui/components/separator';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icons } from '@ui/components/icons';
import { Button } from '@ui/components/button';
import { VehicleSection } from '@/components/case-form/vehicle-section';
import { ClientSection } from '@/components/case-form/client-section';
import { LocationSection } from '@/components/case-form/location-section';
import { SituationSection } from '@/components/case-form/situation-section';
import { DetailSection } from '@/components/case-form/detail-section';

export function NewCasePage() {
  const { t, lang } = useLocale(['case', 'zod']);
  const params = useParams();
  const location = useLocation();
  const user = useUser();

  const navigate = useNavigate();

  const utils = trpc.useUtils();

  React.useEffect(() => {
    if (params.lang !== lang) {
      const path = location.pathname.split('/');
      const [, , ...pathEnd] = path;
      navigate(`/${lang}/${pathEnd.join('/')}`);
    }
  }, [lang, location.pathname, navigate, params.lang]);

  const form = useForm<z.infer<typeof CaseSchema>>({
    resolver: zodResolver(CaseSchema),
    defaultValues: {
      client: {
        fullName: '',
        phoneNumber: '',
      },
      vehicle: {
        registrationPlate: '',
        make: '',
        model: '',
        mileage: undefined,
        driveTrain: DriveTrain.FRONT_WHEEL,
      },
      location: {
        city: '',
        address: '',
        country: {
          name: 'Suomi',
          alfa2: 'FI',
          alfa3: 'FIN',
        },
      },
      situation: {
        personCount: 1,
        hasTrailer: false,
        animalsOnBoard: false,
      },
      creator: {
        id: user?.id,
        email: user?.email,
        name: user?.name,
      },
      comments: '',
    },
  });

  const { mutate: createCase, isLoading } = trpc.case.create.useMutation({
    onSuccess: async () => {
      // refetch all cases in advance for better ux
      // in the case-history page where we are navigating to
      await utils.case.getCases.refetch();
      form.reset();
      navigate(`/${lang}/case-history`);
      toast.success(t('case:new.createdSuccessfully'));
    },
    onError: (error) => {
      console.log(error);
      toast.error(error.message);
    },
  });

  function onSubmit(values: z.infer<typeof CaseSchema>) {
    createCase(values);
  }

  return (
    <DashboardShell title={t('case:new.title')} variant={'large'}>
      <Separator />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid items-start gap-8 pt-2 pb-6"
        >
          <div className="grid gap-8">
            <SituationSection />
            <VehicleSection />
            <ClientSection />
            <LocationSection />
            <DetailSection />
          </div>
          <Button type="submit">
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            {t('case:new.submit')}
          </Button>
        </form>
      </Form>
    </DashboardShell>
  );
}
