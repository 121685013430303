import { Icons } from '@ui/components/icons';
import { cn } from '@ui/lib/utils';
import type { SidebarNavItem } from '@/types';
import { Link, useParams } from 'react-router-dom';
import { CommandMenu } from '../command-menu/command-menu';
import { Button } from '@ui/components/button';
import { Separator } from '@ui/components/separator';
import { useLocale } from '@/hooks/use-language';
import { trpc } from '@/lib/trpc';
import { Badge } from '@ui/components/badge';

interface SideBarNavProps {
  items: SidebarNavItem[];
}

export function SideBarNav({ items }: SideBarNavProps) {
  const params = useParams();
  const { t, lang } = useLocale('nav');

  const { data } = trpc.user.getPendingUsersCount.useQuery();

  return (
    <nav className="grid items-start gap-6 py-4 px-3">
      <div className="grid items-start gap-2">
        <Button className="justify-start" asChild>
          <Link to={`/${lang}/new-case`}>
            <Icons.add className="mr-2 h-4 w-4" />
            {t('nav:newCase')}
          </Link>
        </Button>
        <Separator />
        <div className="grid items-start gap-1">
          <CommandMenu />
          {items.map((item) => {
            const Icon = Icons[item.icon ?? 'chevronRight'];
            return (
              item.href && (
                <Link key={item.title} to={`/${lang}/${item.href}`}>
                  <div>
                    <span
                      className={cn(
                        'group flex items-center justify-between rounded-md px-4 py-2 text-sm text-muted-foreground font-medium hover:bg-input/50 hover:text-primary',
                        params['*']?.includes(item.href)
                          ? 'bg-input text-primary'
                          : 'transparent',
                        item.disabled && 'cursor-not-allowed opacity-80'
                      )}
                    >
                      <div className="flex items-center">
                        <Icon className="mr-2 h-4 w-4" />
                        <span>{t(item.title)}</span>
                      </div>
                      {data && data > 0 && item.href === 'users' ? (
                        <Badge
                          variant={'destructive'}
                          className="-my-1 h-6 w-6 p-0 flex items-center justify-center"
                        >
                          {data}
                        </Badge>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </Link>
              )
            );
          })}
        </div>
      </div>
    </nav>
  );
}
