"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allWorkInstructionFields = exports.vehicleStorageAndReturnFields = exports.customerTransportFields = exports.safetyEquipmentFields = exports.equipmentFailureFields = exports.technicalProblemFields = exports.accommodationFields = exports.replacementCarFields = exports.serviceProvidersFields = exports.serviceCoverageFields = exports.serviceValidityFields = exports.invoicingFields = exports.workInstructionSchema = exports.specificCoverageSchema = exports.contractDetailsSchema = exports.vehicleStorageAndReturnSchema = exports.customerTransportSchema = exports.safetyEquipmentSchema = exports.equipmentFailureSchema = exports.technicalProblemSchema = exports.accommodationSchema = exports.replacementCarSchema = exports.serviceProvidersSchema = exports.serviceCoverageSchema = exports.serviceValiditySchema = exports.invoicingSchema = exports.workInstructionFieldSchema = void 0;
const zod_1 = require("zod");
const DomainServiceChannel_1 = require("./DomainServiceChannel");
exports.workInstructionFieldSchema = zod_1.z.object({
    checked: zod_1.z.boolean().nullable(),
    comment: zod_1.z.string(),
    editedTimestamp: zod_1.z.string().datetime().optional(),
});
exports.invoicingSchema = zod_1.z.object({
    invoiceToRepairShop: exports.workInstructionFieldSchema.optional(),
    invoiceToAl: exports.workInstructionFieldSchema.optional(),
    alContract: exports.workInstructionFieldSchema.optional(),
    arcContract: exports.workInstructionFieldSchema.optional(),
    sendReportToRepairShop: exports.workInstructionFieldSchema.optional(),
});
exports.serviceValiditySchema = zod_1.z.object({
    serviceValidity: exports.workInstructionFieldSchema.optional(),
    fddsVin: exports.workInstructionFieldSchema.optional(),
    customerRegister: exports.workInstructionFieldSchema.optional(),
    alAssistance: exports.workInstructionFieldSchema.optional(),
    maintenanceRequirement: exports.workInstructionFieldSchema.optional(),
    registeredToFinland: exports.workInstructionFieldSchema.optional(),
    validInAllArcCountries: exports.workInstructionFieldSchema.optional(),
});
exports.serviceCoverageSchema = zod_1.z.object({
    technicalAccidental: exports.workInstructionFieldSchema.optional(),
    accident: exports.workInstructionFieldSchema.optional(),
    technicalSelfInflicted: exports.workInstructionFieldSchema.optional(),
    fire: exports.workInstructionFieldSchema.optional(),
    vandalism: exports.workInstructionFieldSchema.optional(),
    theft: exports.workInstructionFieldSchema.optional(),
    appliesToTrailer: exports.workInstructionFieldSchema.optional(),
});
exports.serviceProvidersSchema = zod_1.z.object({
    replacementCar: exports.workInstructionFieldSchema.optional(),
    towing: exports.workInstructionFieldSchema.optional(),
    roadService: exports.workInstructionFieldSchema.optional(),
});
exports.replacementCarSchema = zod_1.z.object({
    grantedImmediately: exports.workInstructionFieldSchema.optional(),
    limitedDistanceFromHome: exports.workInstructionFieldSchema.optional(),
    requiresTowing: exports.workInstructionFieldSchema.optional(),
    maxSize: exports.workInstructionFieldSchema.optional(),
    equilevantToCustomerVehicle: exports.workInstructionFieldSchema.optional(),
    maxLength: exports.workInstructionFieldSchema.optional(),
    pickup: exports.workInstructionFieldSchema.optional(),
    depositFee: exports.workInstructionFieldSchema.optional(),
    deliveryFee: exports.workInstructionFieldSchema.optional(),
    hitch: exports.workInstructionFieldSchema.optional(),
    specialCosts: exports.workInstructionFieldSchema.optional(),
    canBeCombinedWithOtherServices: exports.workInstructionFieldSchema.optional(),
});
exports.accommodationSchema = zod_1.z.object({
    accommodation: exports.workInstructionFieldSchema.optional(),
    limitedAmountOfNights: exports.workInstructionFieldSchema.optional(),
    limitedCost: exports.workInstructionFieldSchema.optional(),
    limitedDistanceFromHome: exports.workInstructionFieldSchema.optional(),
    breakfast: exports.workInstructionFieldSchema.optional(),
    canBeCombinedWithOtherServices: exports.workInstructionFieldSchema.optional(),
});
exports.technicalProblemSchema = zod_1.z.object({
    batteryDrained: exports.workInstructionFieldSchema.optional(),
    fuelFrozen: exports.workInstructionFieldSchema.optional(),
    fuelContaminated: exports.workInstructionFieldSchema.optional(),
    outOfFuel: exports.workInstructionFieldSchema.optional(),
    wrongFuel: exports.workInstructionFieldSchema.optional(),
    keyBroken: exports.workInstructionFieldSchema.optional(),
    keyBrokenInIgnitionSwitch: exports.workInstructionFieldSchema.optional(),
    keyDoesNotGoIntoIgnition: exports.workInstructionFieldSchema.optional(),
    electricalKey: exports.workInstructionFieldSchema.optional(),
    keyInsideLockedCar: exports.workInstructionFieldSchema.optional(),
    keyLost: exports.workInstructionFieldSchema.optional(),
    keyStolen: exports.workInstructionFieldSchema.optional(),
    remoteDoesNotWork: exports.workInstructionFieldSchema.optional(),
    lockDoesNotWork: exports.workInstructionFieldSchema.optional(),
    lockBlocked: exports.workInstructionFieldSchema.optional(),
    wrongKeyInLock: exports.workInstructionFieldSchema.optional(),
    flatTyre: exports.workInstructionFieldSchema.optional(),
    breaks: exports.workInstructionFieldSchema.optional(),
});
exports.equipmentFailureSchema = zod_1.z.object({
    airConditioning: exports.workInstructionFieldSchema.optional(),
    burglarAlarm: exports.workInstructionFieldSchema.optional(),
    burglarAlarmPreventDriving: exports.workInstructionFieldSchema.optional(),
    windshieldOrRearWindowBrokenNotVandalism: exports.workInstructionFieldSchema.optional(),
    convertibleRoofDoesNotWork: exports.workInstructionFieldSchema.optional(),
    doorDoesNotClose: exports.workInstructionFieldSchema.optional(),
    driverSeatStuckCannotDrive: exports.workInstructionFieldSchema.optional(),
    immobilizer: exports.workInstructionFieldSchema.optional(),
    equipmentDoesNotWork: exports.workInstructionFieldSchema.optional(),
    gearboxStuck: exports.workInstructionFieldSchema.optional(),
    problemWithProducts: exports.workInstructionFieldSchema.optional(),
    sideGlassBrokenOrWontClose: exports.workInstructionFieldSchema.optional(),
    stuckOrFrozenChargingPort: exports.workInstructionFieldSchema.optional(),
    stuckOrFrozenChargingCable: exports.workInstructionFieldSchema.optional(),
});
exports.safetyEquipmentSchema = zod_1.z.object({
    blinkers: exports.workInstructionFieldSchema.optional(),
    enginePowerDecreaseOrLoss: exports.workInstructionFieldSchema.optional(),
    statutoryLights: exports.workInstructionFieldSchema.optional(),
    otherLights: exports.workInstructionFieldSchema.optional(),
    powerSteering: exports.workInstructionFieldSchema.optional(),
    driversSeatBelt: exports.workInstructionFieldSchema.optional(),
    passengersSeatBelt: exports.workInstructionFieldSchema.optional(),
    airbagWarningLight: exports.workInstructionFieldSchema.optional(),
    windshieldWipers: exports.workInstructionFieldSchema.optional(),
});
exports.customerTransportSchema = zod_1.z.object({
    taxi: exports.workInstructionFieldSchema.optional(),
    trainOrBus: exports.workInstructionFieldSchema.optional(),
    otherVehicle: exports.workInstructionFieldSchema.optional(),
    airplane: exports.workInstructionFieldSchema.optional(),
    canBeCombinedWithOtherServices: exports.workInstructionFieldSchema.optional(),
});
exports.vehicleStorageAndReturnSchema = zod_1.z.object({
    vehicleStorage: exports.workInstructionFieldSchema.optional(),
    returnFixedVehicle: exports.workInstructionFieldSchema.optional(),
    returnFixedVehicleToHome: exports.workInstructionFieldSchema.optional(),
    returnFixedVehicleToNearestDealer: exports.workInstructionFieldSchema.optional(),
    customerPicksUpFixedVehicle: exports.workInstructionFieldSchema.optional(),
    returnBrokenVehicle: exports.workInstructionFieldSchema.optional(),
});
exports.contractDetailsSchema = zod_1.z.object({
    invoicing: exports.invoicingSchema.optional(),
    serviceValidity: exports.serviceValiditySchema.optional(),
    serviceCoverage: exports.serviceCoverageSchema.optional(),
    serviceProviders: exports.serviceProvidersSchema.optional(),
    replacementCar: exports.replacementCarSchema.optional(),
    accommodation: exports.accommodationSchema.optional(),
    customerTransport: exports.customerTransportSchema.optional(),
    vehicleStorageAndReturn: exports.vehicleStorageAndReturnSchema.optional(),
});
exports.specificCoverageSchema = zod_1.z.object({
    technicalProblem: exports.technicalProblemSchema.optional(),
    equipmentFailure: exports.equipmentFailureSchema.optional(),
    safetyEquipment: exports.safetyEquipmentSchema.optional(),
});
exports.workInstructionSchema = zod_1.z.object({
    _id: zod_1.z.string().optional(),
    name: zod_1.z.string(),
    isEnabled: zod_1.z.boolean().optional(),
    serviceChannels: zod_1.z.array(DomainServiceChannel_1.serviceChannelSchema).optional(),
    description: zod_1.z.string().optional(),
    validity: zod_1.z.string().optional(),
    otherDescription: zod_1.z.string().optional(),
    serviceProviderInfo: zod_1.z.string().optional(),
    serviceNumber: zod_1.z.string().optional(),
    serviceStartDate: zod_1.z.string().optional(),
    serviceEndDate: zod_1.z.string().optional(),
    serviceUpdatedDate: zod_1.z.string().optional(),
    maxVehicleMass: zod_1.z.string().optional(),
    maxExpenses: zod_1.z.string().optional(),
    contractDetails: exports.contractDetailsSchema.optional(),
    specificCoverage: exports.specificCoverageSchema.optional(),
});
exports.invoicingFields = Object.keys(exports.invoicingSchema.shape).map((k) => `contractDetails.invoicing.${k}`);
exports.serviceValidityFields = Object.keys(exports.serviceValiditySchema.shape).map((k) => `contractDetails.serviceValidity.${k}`);
exports.serviceCoverageFields = Object.keys(exports.serviceCoverageSchema.shape).map((k) => `contractDetails.serviceCoverage.${k}`);
exports.serviceProvidersFields = Object.keys(exports.serviceProvidersSchema.shape).map((k) => `contractDetails.serviceProviders.${k}`);
exports.replacementCarFields = Object.keys(exports.replacementCarSchema.shape).map((k) => `contractDetails.replacementCar.${k}`);
exports.accommodationFields = Object.keys(exports.accommodationSchema.shape).map((k) => `contractDetails.accommodation.${k}`);
exports.technicalProblemFields = Object.keys(exports.technicalProblemSchema.shape).map((k) => `specificCoverage.technicalProblem.${k}`);
exports.equipmentFailureFields = Object.keys(exports.equipmentFailureSchema.shape).map((k) => `specificCoverage.equipmentFailure.${k}`);
exports.safetyEquipmentFields = Object.keys(exports.safetyEquipmentSchema.shape).map((k) => `specificCoverage.safetyEquipment.${k}`);
exports.customerTransportFields = Object.keys(exports.customerTransportSchema.shape).map((k) => `contractDetails.customerTransport.${k}`);
exports.vehicleStorageAndReturnFields = Object.keys(exports.vehicleStorageAndReturnSchema.shape).map((k) => `contractDetails.vehicleStorageAndReturn.${k}`);
exports.allWorkInstructionFields = [
    ...exports.invoicingFields,
    ...exports.serviceValidityFields,
    ...exports.serviceCoverageFields,
    ...exports.serviceProvidersFields,
    ...exports.replacementCarFields,
    ...exports.accommodationFields,
    ...exports.technicalProblemFields,
    ...exports.equipmentFailureFields,
    ...exports.safetyEquipmentFields,
    ...exports.customerTransportFields,
    ...exports.vehicleStorageAndReturnFields,
];
