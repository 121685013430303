import { Table } from '@tanstack/react-table';

import { Button } from '@ui/components/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@ui/components/select';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';

interface CaseTablePaginationProps<TData> {
  table: Table<TData>;
}

export function CaseTablePagination<TData>({
  table,
}: CaseTablePaginationProps<TData>) {
  const { t } = useLocale('case-history');

  const filteredSelectedRows = table.getFilteredSelectedRowModel().rows.length;
  const filteredRows = table.getFilteredRowModel().rows.length;
  const selectedRowsText = `${filteredSelectedRows} / ${filteredRows} ${t(
    'case-history:rowsSelected'
  )}`;

  const currentPageText = `${t('case-history:page')} ${
    table.getState().pagination.pageIndex + 1
  } / ${Math.max(table.getPageCount(), 1)}`;

  return (
    <div>
      {table.getState().pagination.pageIndex + 1 === table.getPageCount() &&
        table.getRowModel().rows.length >= 100 && (
          <p className="w-full text-center mb-4 text-sm">
            {t('case-history:limit')}
          </p>
        )}
      <div className="flex items-center justify-between px-2">
        <div className="flex-1 text-sm text-muted-foreground">
          {selectedRowsText}
        </div>
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium hidden md:block">
              {t('case-history:rowsPerPage')}
            </p>
            <Select
              value={`${table.getState().pagination.pageSize}`}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue
                  placeholder={table.getState().pagination.pageSize}
                />
              </SelectTrigger>
              <SelectContent side="top">
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            {currentPageText}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">{t('case-history:toFirstPage')}</span>
              <Icons.doubleChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">
                {t('case-history:toPreviousPage')}
              </span>
              <Icons.chevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">{t('case-history:toNextPage')}</span>
              <Icons.chevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">{t('case-history:toLastPage')}</span>
              <Icons.doubleChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
