import * as React from 'react';
import { Sheet, SheetContent, SheetTrigger } from '@ui/components/sheet';
import { Button } from '@ui/components/button';
import { ScrollArea } from '@ui/components/scroll-area';
import { workspaceConfig } from '@/config/dashboard';
import { cn } from '@ui/lib/utils';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import logo from '@/assets/img/hertz_logo_dark.svg';
import { Separator } from '@ui/components/separator';

export function MobileNav() {
  const [open, setOpen] = React.useState(false);
  const { t, lang } = useLocale('nav');

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="mr-4 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
        >
          <Icons.menu />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <MobileLink to="/" className="flex items-center" onOpenChange={setOpen}>
          <img src={logo} alt="" className="h-8 -my-3"></img>
        </MobileLink>
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 ">
          <div className="grid items-start gap-4 py-4">
            <Button className="justify-start text-base w-full" asChild>
              <Link to={`/${lang}/new-case`}>
                <Icons.add className="mr-2 h-4 w-4" />
                {t('nav:newCase')}
              </Link>
            </Button>
            <Separator />
            <div className="flex flex-col gap-3">
              {workspaceConfig.sidebarNav.map((item) => (
                <div key={item.title} className="flex flex-col">
                  {/* <h4 className="font-medium">{item.title}</h4> */}
                  <React.Fragment key={item.href}>
                    {item.href ? (
                      <MobileLink
                        to={`/${lang}/${item.href}`}
                        onOpenChange={setOpen}
                      >
                        {t(item.title)}
                      </MobileLink>
                    ) : (
                      t(item.title)
                    )}
                  </React.Fragment>
                </div>
              ))}
            </div>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}

interface MobileLinkProps extends LinkProps {
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

function MobileLink({
  to,
  onOpenChange,
  className,
  children,
  ...props
}: MobileLinkProps) {
  const navigate = useNavigate();
  return (
    <Link
      to={to}
      onClick={() => {
        navigate(to.toString());
        onOpenChange?.(false);
      }}
      className={cn(className)}
      {...props}
    >
      {children}
    </Link>
  );
}
