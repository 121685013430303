"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GarageFormVehicleDetailsSchema = exports.GarageFormVehicleDetailsResponseEvent = exports.GarageFormVehicleDetailsRequestEvent = exports.GarageFormCaseCancelEvent = exports.GarageFormCaseUpdateEvent = exports.GarageFormCaseCreateEvent = exports.GarageFormCaseUpdateSchema = exports.GarageRequestSchema = exports.GarageRepatriationRequestSchema = exports.GarageTowAndReplacementRequestSchema = exports.GarageReplacementRequestSchema = exports.ReplacementVehicleServiceSchema = exports.GarageIncidentType = exports.GarageCaseService = void 0;
const zod_1 = require("zod");
const ExternalAgent_1 = require("./ExternalAgent");
exports.GarageCaseService = {
    TOW: 'tow',
    REPLACEMENT_VEHICLE: 'replacement_vehicle',
    REPATRIATION: 'repatriation',
    TOW_AND_REPLACEMENT_VEHICLE: 'tow_and_replacement_vehicle',
};
exports.GarageIncidentType = {
    ACCIDENT: 'accident/collision',
    BREAKDOWN: 'mechanical breakdown',
};
const AddressSchema = zod_1.z.object({
    address: zod_1.z.string(),
    city: zod_1.z.string(),
    zipCode: zod_1.z.string(),
    country: zod_1.z.string(),
});
const GarageSchema = AddressSchema.extend({
    gssnId: zod_1.z.string(),
    name: zod_1.z.string(),
    contactPerson: zod_1.z.string().optional(),
    email: zod_1.z.string().optional(),
    phoneNumber: zod_1.z.string().optional(),
    vatNumber: zod_1.z.string().optional(),
    billing: AddressSchema.extend({
        gssnId: zod_1.z.string(),
        name: zod_1.z.string(),
        email: zod_1.z.string().optional(),
        vatNumber: zod_1.z.string().optional(),
    }),
});
const BaseRequestSchema = zod_1.z.object({
    caseId: zod_1.z.string(),
    assistanceCaseNumber: zod_1.z.string().optional(),
    cacId: zod_1.z.string(),
    service: zod_1.z.nativeEnum(exports.GarageCaseService),
    incidentType: zod_1.z.nativeEnum(exports.GarageIncidentType),
    client: AddressSchema.pick({ city: true, country: true }).partial().extend({
        name: zod_1.z.string(),
        phoneNumber: zod_1.z.string().optional(),
    }),
    garage: GarageSchema.partial({
        phoneNumber: true,
    }),
    vehicle: zod_1.z.object({
        make: zod_1.z.string(),
        model: zod_1.z.string(),
        numberPlate: zod_1.z.string(),
        vin: zod_1.z.string(),
        color: zod_1.z.string().optional(),
        firstRegistrationDate: zod_1.z.string().datetime().optional(),
        height: zod_1.z.string().optional(),
        length: zod_1.z.string().optional(),
        width: zod_1.z.string().optional(),
        weight: zod_1.z.coerce.number(),
        mileage: zod_1.z.coerce.number(),
        driveTrain: zod_1.z.string().optional(),
    }),
    comments: zod_1.z.string().optional(),
    creator: zod_1.z.object({
        name: zod_1.z.string(),
        organisationName: zod_1.z.string().optional(),
        phoneNumber: zod_1.z.string(),
    }),
});
const GarageTowRequestSchema = BaseRequestSchema.extend({
    service: zod_1.z.literal(exports.GarageCaseService.TOW),
    tow: AddressSchema.extend({
        motorway: zod_1.z.boolean(),
        basement: zod_1.z.boolean(),
        trailer: zod_1.z.boolean(),
        locationDescription: zod_1.z.string().optional(),
    }),
    towDestination: GarageSchema.partial({
        gssnId: true,
        name: true,
        billing: true,
    }),
});
exports.ReplacementVehicleServiceSchema = zod_1.z.object({
    from: zod_1.z.string().datetime(),
    until: zod_1.z.string().datetime(),
    dateOfRequest: zod_1.z.string().datetime(),
});
exports.GarageReplacementRequestSchema = BaseRequestSchema.extend({
    service: zod_1.z.literal(exports.GarageCaseService.REPLACEMENT_VEHICLE),
    client: BaseRequestSchema.shape.client.required({
        city: true,
        country: true,
    }),
    replacement: zod_1.z.object({
        dropOff: zod_1.z.string().optional(),
        replacementVehicle: exports.ReplacementVehicleServiceSchema.optional(),
        replacementVehicleExtension: exports.ReplacementVehicleServiceSchema.optional(),
    }),
    replacementVehicleDestination: GarageSchema.partial({
        gssnId: true,
        name: true,
        billing: true,
    }),
});
exports.GarageTowAndReplacementRequestSchema = GarageTowRequestSchema.merge(exports.GarageReplacementRequestSchema).extend({
    service: zod_1.z.literal(exports.GarageCaseService.TOW_AND_REPLACEMENT_VEHICLE),
});
exports.GarageRepatriationRequestSchema = BaseRequestSchema.extend({
    service: zod_1.z.literal(exports.GarageCaseService.REPATRIATION),
    repatriation: zod_1.z.object({
        collectionDate: zod_1.z.string().optional(),
        latestArrivalAtDestination: zod_1.z.string(),
    }),
});
exports.GarageRequestSchema = zod_1.z.discriminatedUnion('service', [
    GarageTowRequestSchema,
    exports.GarageReplacementRequestSchema,
    exports.GarageRepatriationRequestSchema,
    exports.GarageTowAndReplacementRequestSchema,
]);
const BaseInvoicingSchema = zod_1.z.object({
    jobId: zod_1.z.string().optional(),
    invoiceId: zod_1.z.string().optional(),
    relayedDate: zod_1.z.string().optional(),
    serviceProviderName: zod_1.z.string().optional(),
    sum: zod_1.z.number().optional(),
    arrivedDate: zod_1.z.string().optional(),
    repatriation: zod_1.z.boolean(),
});
const GarageFormCaseUpdateBaseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    assistanceCaseNumber: zod_1.z.string(),
    status: zod_1.z.nativeEnum(ExternalAgent_1.ExternalAgentCaseStatus),
    statusText: zod_1.z.nativeEnum(ExternalAgent_1.ExternalAgentCaseStatusText),
    caseId: zod_1.z.string(),
    serviceType: zod_1.z.nativeEnum(exports.GarageCaseService),
    fulfilledServices: zod_1.z
        .array(zod_1.z.discriminatedUnion('serviceType', [
        ExternalAgent_1.RsaOrTowServiceInvoicingSchema,
        ExternalAgent_1.ReplacementServiceInvoicingSchema,
    ]))
        .optional(),
});
const GarageFormCaseTowUpdateSchema = GarageFormCaseUpdateBaseSchema.extend({
    serviceType: zod_1.z.literal(exports.GarageCaseService.TOW),
    distance: zod_1.z.number().optional(),
    towedDistance: zod_1.z.number().optional(),
});
const GarageFormCaseReplacementVehicleUpdateSchema = GarageFormCaseUpdateBaseSchema.extend({
    serviceType: zod_1.z.literal(exports.GarageCaseService.REPLACEMENT_VEHICLE),
});
const GarageFormCaseRepatriationUpdateSchema = GarageFormCaseUpdateBaseSchema.extend({
    serviceType: zod_1.z.literal(exports.GarageCaseService.REPATRIATION),
});
const GarageFormCaseTowAndReplacementVehicleUpdateSchema = GarageFormCaseTowUpdateSchema.merge(GarageFormCaseReplacementVehicleUpdateSchema).extend({
    serviceType: zod_1.z.literal(exports.GarageCaseService.TOW_AND_REPLACEMENT_VEHICLE),
});
exports.GarageFormCaseUpdateSchema = zod_1.z.discriminatedUnion('serviceType', [
    GarageFormCaseTowUpdateSchema,
    GarageFormCaseReplacementVehicleUpdateSchema,
    GarageFormCaseRepatriationUpdateSchema,
    GarageFormCaseTowAndReplacementVehicleUpdateSchema,
]);
exports.GarageFormCaseCreateEvent = 'GarageForm.TaskCard.Create';
exports.GarageFormCaseUpdateEvent = 'GarageForm.TaskCard.Update';
exports.GarageFormCaseCancelEvent = 'GarageForm.TaskCard.Cancel';
exports.GarageFormVehicleDetailsRequestEvent = 'GarageForm.VehicleDetails.Request';
exports.GarageFormVehicleDetailsResponseEvent = 'GarageForm.VehicleDetails.Response';
exports.GarageFormVehicleDetailsSchema = zod_1.z.object({
    requestId: zod_1.z.string(),
    vin: zod_1.z.string().optional(),
    registerNumber: zod_1.z.string().optional(),
    error: zod_1.z.boolean().optional(),
    vehicleDetails: zod_1.z
        .object({
        registrationNumber: zod_1.z.string().optional().nullable(),
        vin: zod_1.z.string().optional().nullable(),
        fuelType: zod_1.z.string().optional().nullable(),
        transmissionType: zod_1.z.string().optional().nullable(),
        insuranceCompany: zod_1.z.string().optional().nullable(),
        mark: zod_1.z.string().optional().nullable(),
        model: zod_1.z.string().optional().nullable(),
        mileage: zod_1.z.string().optional().nullable(),
        mass: zod_1.z.string().optional().nullable(),
        registrationDate: zod_1.z.string().optional().nullable(),
        typeCode: zod_1.z.string().optional().nullable(),
        vehicleTypeCode: zod_1.z.string().optional().nullable(),
        vehicleType: zod_1.z.string().optional().nullable(),
        drivetrainType: zod_1.z.string().optional().nullable(),
        restriction: zod_1.z.string().optional().nullable(),
        currentCountryOfRegistration: zod_1.z
            .object({
            name: zod_1.z.string(),
            alfa2: zod_1.z.string(),
            alfa3: zod_1.z.string(),
        })
            .optional()
            .nullable(),
        color: zod_1.z.string().optional().nullable(),
    })
        .optional()
        .nullable(),
});
