"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HertzOrderFormCaseUpdateSchema = exports.HertzOrderFormRequestSchema = exports.HertzOrderFormCaseCancelEvent = exports.HertzOrderFormCaseUpdateEvent = exports.HertzOrderFormCaseCreateEvent = void 0;
const zod_1 = require("zod");
const ExternalAgent_1 = require("./ExternalAgent");
const SituationType = {
    1: 'Polttoaine loppu',
    2: 'Väärä tankkaus',
    3: 'Itseaiheutettu',
    4: 'Rengasrikko',
    5: 'Liikennevahinko',
    6: 'Ajoneuvo ei käynnisty (akku loppu)',
    7: 'Ajoneuvo ei käynnisty (muu)',
    8: 'Tekninen vika (sammui ajosta)',
    9: 'Tekninen vika (moottori)',
    10: 'Tekninen vika (vaihteisto)',
    11: 'Tekninen vika (voimansiirto)',
    12: 'Tekninen vika (alustanosat)',
    13: 'Tekninen vika (jarrut)',
    14: 'Tekninen vika (korinosat - ikkunat, ovet, lukot)',
    15: 'Uloslukitus',
    16: 'Häiriövalo keltainen (oireeton)',
    17: 'Aiheeton puhelu',
    18: 'Kaskovahinko',
    19: 'Hirvieläinvahinko',
    20: 'Jäätynyt latauskaapeli',
};
exports.HertzOrderFormCaseCreateEvent = 'HertzOrderForm.TaskCard.Create';
exports.HertzOrderFormCaseUpdateEvent = 'HertzOrderForm.TaskCard.Update';
exports.HertzOrderFormCaseCancelEvent = 'HertzOrderForm.TaskCard.Cancel';
const BaseRequestSchema = zod_1.z.object({
    caseId: zod_1.z.string(),
    assistanceCaseNumber: zod_1.z.string().optional(),
    client: zod_1.z.object({
        name: zod_1.z.string(),
        phoneNumber: zod_1.z.string(),
    }),
    vehicle: zod_1.z.object({
        registrationPlate: zod_1.z.string().min(1),
        make: zod_1.z.string().min(1),
        model: zod_1.z.string().min(1),
        mileage: zod_1.z.coerce.number().min(1),
        driveTrain: zod_1.z.nativeEnum(ExternalAgent_1.DriveTrain),
    }),
    location: zod_1.z.object({
        city: zod_1.z.string().min(1),
        address: zod_1.z.string().min(1),
        country: zod_1.z.object({
            name: zod_1.z.string().min(1),
            alfa2: zod_1.z.string().min(2).max(2),
            alfa3: zod_1.z.string().min(3).max(3),
        }),
    }),
    situation: zod_1.z.object({
        situationType: zod_1.z.object({
            id: zod_1.z.string(),
            type: zod_1.z.nativeEnum(SituationType),
        }),
        animalsOnBoard: zod_1.z.boolean(),
        hasTrailer: zod_1.z.boolean(),
        personCount: zod_1.z.coerce.number(),
    }),
    comments: zod_1.z.string().optional(),
    creator: zod_1.z.object({
        email: zod_1.z.string(),
        name: zod_1.z.string(),
    }),
});
exports.HertzOrderFormRequestSchema = BaseRequestSchema.extend({});
const HertzOrderFormCaseUpdateBaseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    assistanceCaseNumber: zod_1.z.string(),
    status: zod_1.z.nativeEnum(ExternalAgent_1.ExternalAgentCaseStatus),
    statusText: zod_1.z.nativeEnum(ExternalAgent_1.ExternalAgentCaseStatusText),
    caseId: zod_1.z.string(),
    fulfilledServices: zod_1.z
        .array(zod_1.z.discriminatedUnion('serviceType', [ExternalAgent_1.RsaOrTowServiceInvoicingSchema]))
        .optional(),
});
// TODO: extend in the future if needed as done in GarageForm
exports.HertzOrderFormCaseUpdateSchema = HertzOrderFormCaseUpdateBaseSchema.extend({});
