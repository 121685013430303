import { Context } from '../adapters/incoming/trpc/context';
import { User, UserRole } from '../models/User';

export const hasRequiredAccess = (
  accessRole: UserRole,
  requiredRole: UserRole
): boolean => {
  if (accessRole === UserRole.ADMIN) return true;
  if (requiredRole === accessRole) return true;

  return false;
};

export const checkUserDetailPermission = (
  currentUser: Context['token'],
  userDetails: User
) => {
  // give admin access to all users
  if (currentUser.role === UserRole.ADMIN) return true;
  // give regular users access only to their own data
  if (
    currentUser.role === UserRole.USER &&
    currentUser.userId === userDetails.id
  )
    return true;

  return false;
};
