import { Button } from '@ui/components/button';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import { Link } from 'react-router-dom';

export function NewCaseNav() {
  const { t, lang } = useLocale('common');
  return (
    <div className="hidden items-center justify-between w-full gap-6 md:gap-10 md:flex mt-4">
      <Button asChild variant={'ghost'}>
        <Link to={`/${lang}/case-history`}>
          <Icons.chevronLeft className="-ml-1 mr-2 h-4 w-4" />
          {t('common:goBack')}
        </Link>
      </Button>
    </div>
  );
}
