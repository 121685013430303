import * as React from 'react';
import { DashboardShell } from '@/components/layout/shell';
import { useLocale } from '@/hooks/use-language';
import { createUserColumns } from '@/components/table/user/user-columns';
import { UserTable } from '@/components/table/user/user-table';
import { trpc } from '@/lib/trpc';
import { UserStatus } from '@/backend/models/User';

export function UsersPage() {
  const { t } = useLocale('users');

  const { data, isLoading } = trpc.user.getAll.useQuery();

  const columns = createUserColumns();

  const users = React.useMemo(
    () => (data ? data.filter((d) => d.status !== UserStatus.REJECTED) : []),
    [data]
  );

  return (
    <DashboardShell title={t('users:title')}>
      <UserTable users={users} columns={columns} isLoading={isLoading} />
    </DashboardShell>
  );
}
