"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoLocationSchema = exports.CountrySchema = exports.LocationType = exports.PointType = void 0;
const zod_1 = require("zod");
exports.PointType = {
    POINT: 'Point',
};
exports.LocationType = {
    STREET: 'Street',
    HOME: 'Home',
    PARKING_GARAGE: 'ParkingGarage',
    HIGHWAY: 'Highway',
    STORAGE_DEPOT: 'StorageDepot',
    OTHER: 'Other',
};
exports.CountrySchema = zod_1.z.object({
    name: zod_1.z.string(),
    alfa2: zod_1.z.string(),
    alfa3: zod_1.z.string(),
});
// https://docs.mongodb.com/manual/reference/geojson/#point
const GeoJSONPointSchema = zod_1.z.object({
    type: zod_1.z.nativeEnum(exports.PointType),
    /**
     * 0: longitude, 1: latitude
     */
    coordinates: zod_1.z.tuple([zod_1.z.number(), zod_1.z.number()]),
});
const GeoAddressSchema = zod_1.z.object({
    label: zod_1.z.string(),
    country: zod_1.z.string(),
    state: zod_1.z.string(),
    county: zod_1.z.string(),
    city: zod_1.z.string(),
    district: zod_1.z.string(),
    street: zod_1.z.string(),
    postalCode: zod_1.z.string(),
    houseNumber: zod_1.z.string(),
});
exports.GeoLocationSchema = GeoJSONPointSchema.extend({
    address: GeoAddressSchema,
});
const GeoPlaceSchema = exports.GeoLocationSchema.extend({
    title: zod_1.z.string().optional(),
    phone: zod_1.z.string().optional(),
    email: zod_1.z.string().optional(),
});
