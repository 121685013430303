import { useLocale } from '@/hooks/use-language';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components/alert-dialog';
import { DropdownMenuItem } from '@ui/components/dropdown-menu';
import { buttonVariants } from '@ui/components/button';
import { Icons } from '@ui/components/icons';

interface UserDeleteAlertProps {
  isLoading: boolean;
  deleteUser: () => Promise<void>;
}

export function UserDeleteAlert({
  isLoading,
  deleteUser,
}: UserDeleteAlertProps) {
  const { t } = useLocale('users');

  return (
    <AlertDialog>
      <DropdownMenuItem
        className="p-0"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <AlertDialogTrigger className="w-full px-2 py-1.5 flex items-center cursor-default">
          <span className="text-destructive">{t('users:actions.delete')}</span>
        </AlertDialogTrigger>
      </DropdownMenuItem>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('users:dialog.delete.title')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('users:dialog.delete.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('users:dialog.delete.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: 'destructive' })}
            onClick={async (event) => {
              event.preventDefault();
              await deleteUser();
            }}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.trash className="mr-2 h-4 w-4" />
            )}
            {t('users:dialog.delete.action')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
