import { Table } from '@tanstack/react-table';
import { Button } from '@ui/components/button';
import { Input } from '@ui/components/input';
import { CaseTableViewOptions } from './case-table-view-options';
import { statuses } from './data';
import { CaseTableFacetedFilter } from './case-table-faceted-filter';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import { useSearchParams } from 'react-router-dom';

interface CaseTableToolbarProps<TData> {
  table: Table<TData>;
}

export function CaseTableToolbar<TData>({
  table,
}: CaseTableToolbarProps<TData>) {
  const { t } = useLocale('case-history');

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');

  const isFiltered =
    Array.from(searchParams.values()).filter((v) => v !== '').length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder={t('case-history:search')}
          value={searchQuery ?? ''}
          onChange={(event) =>
            setSearchParams((currentSearchParams) => {
              const updatedSearchParams = new URLSearchParams(
                currentSearchParams
              );
              updatedSearchParams.set('search', event.target.value);
              return updatedSearchParams.toString();
            })
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn('status') && (
          <CaseTableFacetedFilter title="Status" options={statuses} />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {
              setSearchParams('');
            }}
            className="h-8 px-2 lg:px-3"
          >
            {t('case-history:reset')}
            <Icons.close className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <CaseTableViewOptions table={table} />
    </div>
  );
}
