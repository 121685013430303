import { CommandItem } from '@ui/components/command';

export function Item({
  children,
  shortcut,
  onSelect = () => {},
}: {
  children: React.ReactNode;
  shortcut?: string;
  onSelect?: (value: string) => void;
}) {
  return (
    <CommandItem onSelect={onSelect}>
      {children}
      {shortcut && (
        <div>
          {shortcut.split(' ').map((key) => {
            return (
              <kbd
                key={key}
                className="top-2.25 pointer-events-none absolute right-9 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex"
              >
                <span className="text-xs">{key}</span>
              </kbd>
            );
          })}
        </div>
      )}
    </CommandItem>
  );
}
