import * as React from 'react';
import { useLocale } from '@/hooks/use-language';
import { useNavigate, useParams } from 'react-router-dom';

export function RedirectComponent() {
  const navigate = useNavigate();
  const params = useParams();

  const { lang } = useLocale();

  React.useEffect(() => {
    if (!params.lang || params.lang !== lang || !params['*']) {
      navigate(`/${lang}/case-history`);
    }
  }, [navigate, params.lang, lang, params]);

  return <div></div>;
}
