import { type WorkspaceConfig } from '@/types';

export const workspaceConfig: WorkspaceConfig = {
  mainNav: [],
  sidebarNav: [
    {
      title: 'nav:caseHistory',
      href: 'case-history',
      icon: 'page',
    },
    {
      title: 'nav:users',
      href: 'users',
      icon: 'users',
    },
  ],
};
