import * as React from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Icons } from '@ui/components/icons';
import { useSearchParams } from 'react-router-dom';
import { useLoginUrls } from '@/hooks/use-auth';
import { useLocale } from '@/hooks/use-language';
import toast from 'react-hot-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@ui/components/form';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@ui/components/input-otp';

const userCodeSchema = z.object({
  code: z.string(),
});

type FormData = z.infer<typeof userCodeSchema>;

export function CodePage() {
  const form = useForm<FormData>({
    resolver: zodResolver(userCodeSchema),
  });

  const { t } = useLocale('auth');

  const { lang } = useLocale();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const loginUrls = useLoginUrls();

  const handleNewCode = async () => {
    try {
      const email = searchParams.get('email');
      if (!email) {
        throw new Error('No email as a search param');
      }

      location.href = loginUrls.email(email, lang as 'fi' | 'en', true);
    } catch (error) {
      console.log(error);
    }
  };

  const error = searchParams.get('error');
  const redirect = searchParams.get('redirect');
  const timestamp = searchParams.get('timestamp');

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (error && timestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - Number(timestamp) < 30000) {
        timeout = setTimeout(() => {
          switch (error) {
            case 'invalid_code':
              return toast.error(t('auth:code.error.invalidCode'), {
                position: 'bottom-right',
                duration: 8000,
              });
            default:
              return toast.error(t('auth:code.error.unknown'), {
                position: 'bottom-right',
                duration: 8000,
              });
          }
        }, 100);
      }
    } else if (redirect && timestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - Number(timestamp) < 30000) {
        timeout = setTimeout(() => {
          switch (redirect) {
            case 'new_code':
              return toast.success(t('auth:code.newCodeSent'), {
                position: 'bottom-right',
                duration: 8000,
              });
            default:
              return;
          }
        }, 100);
      }
    }

    return () => clearTimeout(timeout);
  }, [error, timestamp, t, redirect]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);

    try {
      const params = new URLSearchParams({
        code: data.code,
      });

      location.href =
        import.meta.env.VITE_AUTH_URL + '/email/callback?' + params.toString();
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const handleChange = (value: string) => {
    form.setValue('code', value);

    if (value.length === 6) {
      onSubmit({ code: value });
    }
  };

  return (
    <div className="container flex h-full w-full max-w-none flex-col items-center justify-center min-h-[550px]">
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] -mt-16">
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-3xl font-bold tracking-tight font-raleway">
            {t('auth:code.title')}
          </h1>
          <p className="text-md text-slate-500 dark:text-slate-400">
            {t('auth:code.description')}
          </p>
        </div>
        <div className="bg-muted flex flex-col items-start p-3 rounded-md px-5 gap-1 text-sm text-slate-500 dark:text-slate-300">
          <div className="flex gap-2.5">
            <div className="h-5 flex items-center justify-center">
              <Icons.circle className="h-1.5 w-1.5" />
            </div>
            <span>{t('auth:code.helpers.codeLocation')}</span>
          </div>
          <div className="flex gap-2.5">
            <div className="h-5 flex items-center justify-center">
              <Icons.circle className="h-1.5 w-1.5" />
            </div>
            <span>{t('auth:code.helpers.trash')}</span>
          </div>
        </div>
        <Form {...form}>
          <form>
            <div className="grid gap-6">
              <div className="grid gap-1">
                <FormField
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        {t('auth:code.helpers.code')}
                      </FormLabel>
                      <FormControl>
                        <InputOTP
                          maxLength={6}
                          {...field}
                          onChange={handleChange}
                          disabled={isLoading}
                        >
                          <InputOTPGroup className="w-full">
                            <InputOTPSlot className="h-12 flex-1" index={0} />
                            <InputOTPSlot className="h-12 flex-1" index={1} />
                            <InputOTPSlot className="h-12 flex-1" index={2} />
                            <InputOTPSlot className="h-12 flex-1" index={3} />
                            <InputOTPSlot className="h-12 flex-1" index={4} />
                            <InputOTPSlot className="h-12 flex-1" index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </form>
        </Form>
        <div>
          <p className="px-8 text-center text-sm text-slate-500 dark:text-slate-400">
            {t('auth:code.helpers.noCode')}
          </p>
          <p className="px-8 text-center text-sm text-slate-500 dark:text-slate-400">
            <button
              onClick={handleNewCode}
              className="hover:text-brand underline underline-offset-4"
            >
              {t('auth:code.helpers.sendNewCode')}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}
