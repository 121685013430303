import { z } from 'zod';
import { CaseFormShell } from './case-form-shell';
import { useFormContext } from 'react-hook-form';
import { CaseSchema } from '@/backend/models/Case';
import { useLocale } from '@/hooks/use-language';
import { CaseFormTextAreaField } from './case-form-text-area-field';
import { CaseFormRadioGroupField } from './case-form-radio-group-field';

export function DetailSection() {
  const form = useFormContext<z.infer<typeof CaseSchema>>();
  const { t } = useLocale(['case', 'common']);

  const options = [
    { label: t('common:true'), value: 'true' },
    { label: t('common:false'), value: 'false' },
  ];

  const handleTrailerSelect = (value: string) => {
    if (value === 'true') {
      form.setValue('situation.hasTrailer', true);
    } else {
      form.setValue('situation.hasTrailer', false);
    }
  };

  const handleAnimalSelect = (value: string) => {
    if (value === 'true') {
      form.setValue('situation.animalsOnBoard', true);
    } else {
      form.setValue('situation.animalsOnBoard', false);
    }
  };

  return (
    <CaseFormShell title={t('case:new.details.title')}>
      <div className="grid gap-6">
        <CaseFormRadioGroupField
          {...form}
          name="situation.hasTrailer"
          label={t('case:new.situation.hasTrailer.label')}
          options={options}
          onSelect={handleTrailerSelect}
        />
        <CaseFormRadioGroupField
          {...form}
          name="situation.animalsOnBoard"
          label={t('case:new.situation.animalsOnBoard.label')}
          options={options}
          onSelect={handleAnimalSelect}
        />
        <CaseFormTextAreaField
          {...form}
          name="comments"
          label={t('case:new.details.comments.label')}
          placeholder={t('case:new.details.comments.placeholder')}
        />
      </div>
    </CaseFormShell>
  );
}
