import { cn } from '@ui/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@ui/components/popover';
import { Separator } from '@ui/components/separator';
import { Button } from '@ui/components/button';
import { Badge } from '@ui/components/badge';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import { useSearchParams } from 'react-router-dom';

interface CaseTableFacetedFilterProps {
  title?: string;
  options: {
    value: string;
    icon: keyof typeof Icons;
  }[];
}

export function CaseTableFacetedFilter({
  title,
  options,
}: CaseTableFacetedFilterProps) {
  const { t } = useLocale(['case', 'common']);

  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get('status');

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <Icons.plusCircle className="mr-2 h-4 w-4" />
          {title}
          {status && status.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="hidden space-x-1 lg:flex">
                {options
                  .filter((option) => option.value === status)
                  .map((option) => (
                    <Badge
                      variant="secondary"
                      key={option.value}
                      className="rounded-sm px-1 font-normal"
                    >
                      {t(`case:status.${option.value}`)}
                    </Badge>
                  ))}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>{t('common:noResults')}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = status === option.value;
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setSearchParams((currentSearchParams) => {
                        const updatedSearchParams = new URLSearchParams(
                          currentSearchParams
                        );
                        isSelected
                          ? updatedSearchParams.delete('status')
                          : updatedSearchParams.set('status', option.value);

                        return updatedSearchParams;
                      });
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <Icons.check className={cn('h-4 w-4')} />
                    </div>
                    <span>{t(`case:status.${option.value}`)}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
