import * as React from 'react';
import { Table } from '@tanstack/react-table';
import { Input } from '@ui/components/input';
import { useLocale } from '@/hooks/use-language';
import { UserTableOptions } from './user-table-options';
import { RejectedUserTableDialog } from '../rejected-user/rejected-user-table-dialog';
import { Separator } from '@ui/components/separator';
import { useSearchParams } from 'react-router-dom';

interface UserTableToolbarProps<TData> {
  table: Table<TData>;
  disabled: boolean;
}

export function UserTableToolbar<TData>({
  table,
  disabled,
}: UserTableToolbarProps<TData>) {
  const { t } = useLocale('users');

  const [rejectedUsersOpen, setRejectedUsersOpen] =
    React.useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');
  const rejectedUsers = searchParams.get('rejectedUsers');

  React.useEffect(() => {
    if (searchQuery) {
      table.getColumn('data')?.setFilterValue(searchQuery);
    } else if (rejectedUsers && rejectedUsers === 'true') {
      setRejectedUsersOpen(true);
    }
  }, [rejectedUsers, searchQuery, table]);

  return (
    <div className="flex items-center space-x-2">
      <Input
        disabled={disabled}
        placeholder={t('users:search')}
        value={(table.getColumn('data')?.getFilterValue() as string) ?? ''}
        onChange={(event) =>
          table.getColumn('data')?.setFilterValue(event.target.value)
        }
        className="h-8 w-[150px] lg:w-[250px]"
      />
      <Separator orientation="vertical" className="h-6" />
      <UserTableOptions setRejectedUsersOpen={setRejectedUsersOpen} />
      <RejectedUserTableDialog
        open={rejectedUsersOpen}
        setOpen={setRejectedUsersOpen}
        setSearchParams={setSearchParams}
      />
    </div>
  );
}
