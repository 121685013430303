import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@ui/components/dialog';
import { useLocale } from '@/hooks/use-language';
import { RejectedUserTable } from './rejected-user-table';
import { createRejectedUserColumns } from './rejected-user-columns';
import { ScrollArea } from '@ui/components/scroll-area';
import { trpc } from '@/lib/trpc';
import { UserStatus } from '@/backend/models/User';
import { SetURLSearchParams } from 'react-router-dom';

interface RejectedUserTableDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchParams: SetURLSearchParams;
}

export function RejectedUserTableDialog({
  open,
  setOpen,
  setSearchParams,
}: RejectedUserTableDialogProps) {
  const { t } = useLocale('users');

  const columns = createRejectedUserColumns();

  const { data, isLoading } = trpc.user.getAll.useQuery();

  const rejectedUsers = React.useMemo(
    () => (data ? data.filter((d) => d.status === UserStatus.REJECTED) : []),
    [data]
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setSearchParams('');
        }
        setOpen(open);
      }}
    >
      <DialogContent className="p-0">
        <DialogHeader className="p-6 pb-0">
          <DialogTitle>{t('users:rejectedUsers')}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[60vh]">
          <RejectedUserTable
            data={rejectedUsers}
            isLoading={isLoading}
            columns={columns}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
