import type { InitOptions, Namespace } from 'i18next';

export const fallbackLng = 'fi';
export const languages = [fallbackLng, 'en'];
export const defaultNS = 'common';
export const namespaces = [
  defaultNS,
  'nav',
  'users',
  'auth',
  'case',
  'case-history',
  'zod',
];

export const getOptions = (ns: Namespace = defaultNS): InitOptions => ({
  supportedLngs: languages,
  fallbackLng,
  fallbackNS: defaultNS,
  defaultNS,
  ns,
  // debug: true,
  preload: languages,
  detection: {
    // order and from where user language should be detected
    order: [
      'path',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
      'htmlTag',
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  },
});
