import { ColumnDef } from '@tanstack/react-table';

import { statuses } from './data';
import { CaseTableColumnHeader } from './case-table-column-header';
import { CaseTableRowActions } from './case-table-row-actions';
import { Checkbox } from '@ui/components/checkbox';
import { Case } from '@/backend/models/Case';
import dayjs from 'dayjs';
import { Icons } from '@ui/components/icons';
import { KeyPrefix, TFunction, Namespace as Ns } from 'i18next';

export const createCaseColumns = (
  t: TFunction<string | readonly string[], KeyPrefix<Ns>>
): ColumnDef<Case>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'vehicle.registrationPlate',
    header: ({ column }) => (
      <CaseTableColumnHeader
        column={column}
        title={t('case-history:registrationPlate')}
        className=" min-w-[120px]"
      />
    ),
    cell: ({ row }) => (
      <div className="min-w-[120px]">
        {row.getValue('vehicle_registrationPlate')}
      </div>
    ),

    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <CaseTableColumnHeader
        column={column}
        title={t('case-history:status')}
        className="min-w-[160px]"
      />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue('status')
      );

      if (!status) {
        return null;
      }

      const Icon = Icons[status.icon];

      return (
        <div className="flex min-w-[160px] items-center">
          <Icon className="mr-2 h-4 w-4 text-muted-foreground" />
          <span>{t(`case:status.${status.value}`)}</span>
        </div>
      );
    },
    enableSorting: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'assistanceCaseNumber',
    header: ({ column }) => (
      <CaseTableColumnHeader
        column={column}
        title={t('case-history:assistanceCaseNumber')}
        className="min-w-[125px] max-w-[125px]"
      />
    ),
    cell: ({ row }) => (
      <div className="min-w-[125px] max-w-[125px]">
        {row.getValue('assistanceCaseNumber')}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <CaseTableColumnHeader
        column={column}
        title={t('case-history:createdAt')}
        className=" min-w-[160px]"
      />
    ),
    cell: ({ row }) => (
      <div className="min-w-[160px]">
        {dayjs(row.getValue('createdAt')).format('DD.MM.YYYY HH:mm')}
      </div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: 'actions',
    header: () => <div className="w-8 h-8"></div>,
    cell: ({ table, row }) => (
      <div className="flex justify-end">
        <CaseTableRowActions table={table} row={row} />
      </div>
    ),
  },
];
