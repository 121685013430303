import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ui/components/form';
import { Input } from '@ui/components/input';
import { Control, FieldValues, Path, PathValue } from 'react-hook-form';

export interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder: string;
  description?: string;
  defaultValue?: PathValue<T, Path<T>>;
  className?: string;
}

export function CaseFormTextField<T extends FieldValues>(props: Props<T>) {
  const { name, control, label, placeholder, description, className } = props;
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input placeholder={placeholder} className={className} {...field} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
