"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailConfigDomainSchema = exports.EmailConfigMongoSchema = exports.EmailConfigType = void 0;
const zod_1 = require("zod");
exports.EmailConfigType = {
    NEW_CASE_NOTIFICATION: 'newCaseNotification',
};
const EmailConfigSchema = (idType) => zod_1.z.object({
    _id: idType,
    type: zod_1.z.nativeEnum(exports.EmailConfigType),
    disabledDate: zod_1.z.string().optional(),
    disabledFrom: zod_1.z.string(),
    disabledTo: zod_1.z.string(),
});
exports.EmailConfigMongoSchema = EmailConfigSchema(zod_1.z.custom().optional());
exports.EmailConfigDomainSchema = EmailConfigSchema(zod_1.z.string());
