import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './globals.css';
import { AuthProvider, store } from './context/auth';

import { RedirectComponent } from './components/layout/redirect';
import { RootLayout } from './pages/root-layout';
import { AuthLayout } from './pages/auth/auth-layout';
import { DashboardLayout } from './pages/dashboard/dashboard-layout';
import { LoginPage } from './pages/auth/login/login-page';
import { CodePage } from './pages/auth/code/code-page';
import { ThemeProvider } from './context/theme';
import { UsersPage } from './pages/dashboard/users/users-page';
import { CaseHistoryPage } from './pages/dashboard/case-history/case-history-page';
import { NewCasePage } from './pages/dashboard/new-case/new-case-page';
import { NewCaseLayout } from './components/layout/new-case-layout';
import { RegisterPage } from './pages/auth/register/register-page';

import './i18n';
import { RealtimeProvider } from './context/realtime';

const router = createBrowserRouter([
  {
    path: ':lang/auth',
    element: <AuthLayout />,
    loader: ({ params }) => {
      const lang = params.lang;
      const user = store.get();
      if (user) {
        location.href = `${import.meta.env.VITE_APP_URL}/${lang}/case-history`;
      }
      return {};
    },
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'code', element: <CodePage /> },
      { path: 'callback', element: <CodePage /> },
    ],
  },
  {
    path: ':lang/new-case',
    element: <NewCaseLayout />,
    loader: ({ params }) => {
      const lang = params.lang;
      const user = store.get();
      if (!user) {
        location.href = `${import.meta.env.VITE_APP_URL}/${lang}/auth/login`;
      }
      return {};
    },
    children: [{ path: '', element: <NewCasePage /> }],
  },
  {
    path: ':lang/*',
    element: (
      <RealtimeProvider>
        <DashboardLayout />
      </RealtimeProvider>
    ),
    loader: ({ params }) => {
      const lang = params.lang;
      const user = store.get();
      const allowedRoutes = ['users', 'case-history'];
      if (!user) {
        location.href = `${import.meta.env.VITE_APP_URL}/${lang}/auth/login`;
      } else if (params['*'] && !allowedRoutes.includes(params['*'])) {
        location.href = `${import.meta.env.VITE_APP_URL}/${lang}/case-history`;
      }
      return {};
    },
    children: [
      { path: 'users', element: <UsersPage /> },
      { path: 'case-history', element: <CaseHistoryPage /> },
      { path: '*', element: <RedirectComponent /> },
    ],
  },
  {
    path: '*',
    element: <RedirectComponent />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    {/* suspense is used for i18n */}
    <React.Suspense fallback={<></>}>
      <AuthProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <RootLayout>
            <RouterProvider router={router} />
          </RootLayout>
        </ThemeProvider>
      </AuthProvider>
    </React.Suspense>
  </React.StrictMode>
);
