import { useLocale } from '@/hooks/use-language';
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from '@ui/components/command';
import { trpc } from '@/lib/trpc';
import { Icons } from '@ui/components/icons';

export function CommandMenuUsers({
  onSelect,
}: {
  onSelect: (value: string) => void;
}) {
  const { t } = useLocale('nav');

  const { data, isLoading } = trpc.user.getAll.useQuery();

  return (
    <>
      {isLoading && !data ? (
        <CommandEmpty>{t('nav:commandMenu.loading')}</CommandEmpty>
      ) : (
        <CommandEmpty>{t('nav:commandMenu.noResults')}</CommandEmpty>
      )}
      {data && data.length > 0 && (
        <CommandGroup heading={t('nav:users')}>
          {data?.map(({ id, name }) => {
            return (
              <CommandItem
                key={id}
                onSelect={onSelect}
                value={JSON.stringify({ id, name })}
              >
                <div className="mr-2 flex h-4 w-4 items-center justify-center">
                  <Icons.circle className="h-3 w-3" />
                </div>
                {name}
              </CommandItem>
            );
          })}
        </CommandGroup>
      )}
    </>
  );
}
