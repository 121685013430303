import { useLocale } from '@/hooks/use-language';
import { Link } from 'react-router-dom';
import { Icons } from '@ui/components/icons';

export function MobileNewCaseNav() {
  const { t, lang } = useLocale('common');
  return (
    <div className="flex gap-6 md:gap-10 md:hidden">
      <Link
        to={`/${lang}/case-history`}
        className="flex items-center text-sm py-2 font-medium"
      >
        <Icons.chevronLeft className="-ml-1 mr-2 h-4 w-4" />
        {t('common:goBack')}
      </Link>
    </div>
  );
}
