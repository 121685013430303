import { useLocale } from '@/hooks/use-language';
import { UserLoginForm } from './user-login-form';
import { Icons } from '@ui/components/icons';

export function LoginPage() {
  const { t } = useLocale('auth');

  return (
    <div className="container flex h-full w-full max-w-none flex-col items-center justify-center min-h-[550px]">
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] -mt-16">
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-3xl font-bold tracking-tight font-raleway">
            {t('auth:login.title')}
          </h1>
          <p className="text-md text-slate-500 dark:text-slate-400">
            {t('auth:login.description')}
          </p>
        </div>
        <div className="bg-muted flex flex-col items-start p-3 rounded-md px-5 gap-1 text-sm text-slate-500 dark:text-slate-300">
          <div className="flex gap-2.5">
            <div className="h-5 flex items-center justify-center">
              <Icons.circle className="h-1.5 w-1.5" />
            </div>
            <span>{t('auth:login.helpers.noPassword')}</span>
          </div>
          <div className="flex gap-2.5">
            <div className="h-5 flex items-center justify-center">
              <Icons.circle className="h-1.5 w-1.5" />
            </div>
            <span>{t('auth:login.helpers.codeToEmail')}</span>
          </div>
        </div>
        <UserLoginForm />
      </div>
    </div>
  );
}
