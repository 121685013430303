"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriveTrain = exports.ReplacementServiceInvoicingSchema = exports.RsaOrTowServiceInvoicingSchema = exports.BaseInvoicingSchema = exports.AddressSchema = exports.ExternalAgentStatusResponseSchema = exports.ExternalAgentCaseStatusText = exports.ExternalAgentCaseStatus = exports.ExternalAgentJobRequestSchema = exports.LtChatBotRequestSchema = exports.AlChatBotRequestSchema = exports.MobileAppRequestSchema = exports.ExternalAgentType = void 0;
const zod_1 = require("zod");
const domain_1 = require("../domain");
exports.ExternalAgentType = {
    MOBILE_APP: 'mobileApp',
    CHAT_BOT: 'chatbot',
    LT_CHAT_BOT: 'ltChatbot',
    GARAGE_FORM: 'garageForm',
    HERTZ_ORDER_FORM: 'hertzOrderForm',
    REDGO_ORDER_FORM: 'redgoOrderForm',
    SPARX: 'sparx',
};
const ExternalAgentJobRequestBaseSchema = zod_1.z.object({
    client: zod_1.z.object({
        name: zod_1.z.string(),
        phonenumber: zod_1.z.string().optional(),
        country: domain_1.CountrySchema.optional(),
        personCount: zod_1.z.number().optional(),
        fdds: zod_1.z.number().optional(),
    }),
    location: zod_1.z.object({
        city: zod_1.z.string(),
        address: zod_1.z.string(),
        country: domain_1.CountrySchema,
        description: zod_1.z.string().optional(),
        coordinates: zod_1.z
            .object({
            latitude: zod_1.z.number(),
            longitude: zod_1.z.number(),
        })
            .optional(),
    }),
    vehicle: zod_1.z.object({
        registrationNumber: zod_1.z.string(),
    }),
    situation: zod_1.z.object({
        situationType: zod_1.z.object({
            id: zod_1.z.number(),
            description: zod_1.z.string(),
        }),
        description: zod_1.z.string().optional(),
    }),
    comments: zod_1.z.string().optional(),
});
exports.MobileAppRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
    type: zod_1.z.literal(exports.ExternalAgentType.MOBILE_APP),
    client: ExternalAgentJobRequestBaseSchema.shape.client.extend({
        id: zod_1.z.number(),
        homeTown: zod_1.z.string().optional(),
        membershipValid: zod_1.z.boolean().optional(),
        fdds: zod_1.z.number().optional(),
    }),
    vehicle: ExternalAgentJobRequestBaseSchema.shape.vehicle.extend({
        mileage: zod_1.z.coerce.string().optional(),
    }),
    situation: ExternalAgentJobRequestBaseSchema.shape.situation.extend({
        animalsOnBoard: zod_1.z.boolean(),
        hasTrailer: zod_1.z.boolean(),
        faultInTheTrailer: zod_1.z.boolean(),
    }),
});
exports.AlChatBotRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
    type: zod_1.z.literal(exports.ExternalAgentType.CHAT_BOT),
    client: ExternalAgentJobRequestBaseSchema.shape.client.extend({
        id: zod_1.z.number(),
        homeTown: zod_1.z.string().optional(),
        membershipValid: zod_1.z.boolean().optional(),
    }),
    vehicle: ExternalAgentJobRequestBaseSchema.shape.vehicle.extend({
        mileage: zod_1.z.coerce.string().optional(),
    }),
    situation: ExternalAgentJobRequestBaseSchema.shape.situation.extend({
        animalsOnBoard: zod_1.z.boolean(),
        hasTrailer: zod_1.z.boolean(),
        faultInTheTrailer: zod_1.z.boolean(),
    }),
});
exports.LtChatBotRequestSchema = ExternalAgentJobRequestBaseSchema.extend({
    type: zod_1.z.literal(exports.ExternalAgentType.LT_CHAT_BOT),
});
/* Request made by either mobileApp or chatbot */
exports.ExternalAgentJobRequestSchema = zod_1.z.discriminatedUnion('type', [
    exports.AlChatBotRequestSchema,
    exports.LtChatBotRequestSchema,
    exports.MobileAppRequestSchema,
]);
exports.ExternalAgentCaseStatus = {
    AwaitingOperatorAction: 1,
    OpenedByOperator: 2,
    AwaitingAssistanceResponse: 3,
    AssistanceOnTheWay: 4,
    CancelRequestPending: 5,
    CancelRequestProcessing: 6,
    Cancelled: 7,
    Ready: 8,
};
exports.ExternalAgentCaseStatusText = {
    1: 'Awaiting operator action',
    2: 'Case opened by an operator',
    3: 'Awaiting assistance response',
    4: 'Assistance on the way',
    5: 'Cancel request pending, awaiting operator action',
    6: 'Case opened for cancelling by an operator',
    7: 'Case succesfully cancelled',
    8: 'Case completed successfully',
};
exports.ExternalAgentStatusResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    status: zod_1.z.nativeEnum(exports.ExternalAgentCaseStatus),
    statusText: zod_1.z.nativeEnum(exports.ExternalAgentCaseStatusText),
});
exports.AddressSchema = zod_1.z.object({
    address: zod_1.z.string(),
    city: zod_1.z.string(),
    zipCode: zod_1.z.string(),
    country: zod_1.z.string(),
});
exports.BaseInvoicingSchema = zod_1.z.object({
    jobId: zod_1.z.string().optional(),
    invoiceId: zod_1.z.string().optional(),
    relayedDate: zod_1.z.string().optional(),
    serviceProviderName: zod_1.z.string().optional(),
    sum: zod_1.z.number().optional(),
    arrivedDate: zod_1.z.string().optional(),
    repatriation: zod_1.z.boolean(),
});
exports.RsaOrTowServiceInvoicingSchema = exports.BaseInvoicingSchema.extend({
    serviceType: zod_1.z.literal('rsaOrTrailer'),
    serviceDescription: zod_1.z.string().optional(),
    serviceProviderAddress: exports.AddressSchema.optional(),
    readyDate: zod_1.z.string(),
    distance: zod_1.z.number().optional(),
    towedDistance: zod_1.z.number().optional(),
    trailerInsistedByCustomer: zod_1.z.boolean(),
    invoicedFromOtherParty: zod_1.z.boolean(),
});
exports.ReplacementServiceInvoicingSchema = exports.BaseInvoicingSchema.extend({
    serviceType: zod_1.z.literal('replacementCar'),
    readyDate: zod_1.z.string().optional(),
    location: zod_1.z.string().optional(),
    pickUpLocation: exports.AddressSchema.optional(),
    dropOffLocation: exports.AddressSchema.optional(),
    days: zod_1.z.number().optional(),
    vehicleTypeCode: zod_1.z.string().optional(),
    vehicleMark: zod_1.z.string().optional(),
    vehicleModel: zod_1.z.string().optional(),
    additionalMessage: zod_1.z.string().optional(),
});
// these are ARC drive train types
exports.DriveTrain = {
    FRONT_WHEEL: 'FrontWheelDrive',
    REAR_WHEEL: 'RearWheelDrive',
    FOUR_WHEEL: 'FourWheelDrive',
    // ALL_WHEEL: 'AllWheelDrive',
};
