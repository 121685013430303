import * as React from 'react';
import { Button } from '@ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@ui/components/dialog';
import { Icons } from '@ui/components/icons';
import { ScrollArea } from '@ui/components/scroll-area';
import { Separator } from '@ui/components/separator';
import { useLocale } from '@/hooks/use-language';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { trpc } from '@/lib/trpc';

function CaseDetailRow({ label, value }: { label: string; value: string }) {
  return (
    <div className="grid grid-cols-3 items-center gap-4">
      <p className="text-sm text-muted-foreground">{label}</p>
      <p className="col-span-2 text-sm">{value}</p>
    </div>
  );
}

export function CaseDetailDialog() {
  const [open, setOpen] = React.useState<boolean>(false);
  const { t } = useLocale(['case-history', 'case', 'common']);

  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const {
    data: caseDetails,
    isLoading,
    refetch,
  } = trpc.case.getCaseById.useQuery(caseId ?? '', {
    enabled: !!caseId,
  });

  React.useEffect(() => {
    if (caseId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [caseId, refetch]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setSearchParams((currentSearchParams) => {
          const updatedSearchParams = new URLSearchParams(currentSearchParams);
          updatedSearchParams.delete('caseId');
          return updatedSearchParams.toString();
        });
        setOpen(open);
      }}
    >
      {caseDetails ? (
        <DialogContent
          onOpenAutoFocus={(event) => event.preventDefault()}
          className="px-0 pb-1 min-w-[75vw] max-w-[90vw] lg:max-w-[700px] lg:min-w-[700px]"
          onCloseAutoFocus={(event) => event.preventDefault()}
        >
          <DialogHeader className="px-6 border-b pb-5">
            <DialogDescription className="flex gap-2 items-center">
              <span>{t('case-history:title')}</span>
              <span>/</span>
              <span className="text-foreground font-medium">
                {caseDetails.vehicle.registrationPlate}
              </span>
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="h-[75vh] px-5 -my-1 -mt-4">
            <div className="grid gap-6 mt-4 overflow-y-auto px-1 py-1">
              <div className="flex items-center justify-between -mx-1 px-1">
                <h1 className="text-3xl font-semibold font-raleway leading-none tracking-tight">
                  {caseDetails.vehicle.registrationPlate}
                </h1>
              </div>
              <div className="grid gap-2">
                <CaseDetailRow
                  label={'Status'}
                  value={t(`case:status.${caseDetails.status}`)}
                />
                <CaseDetailRow
                  label={t('case-history:details.dateAndTime')}
                  value={dayjs(caseDetails.createdAt).format(
                    'DD.MM.YYYY HH:mm'
                  )}
                />
                <CaseDetailRow
                  label={t('case-history:details.creatorName')}
                  value={caseDetails.creator.name}
                />
                <CaseDetailRow
                  label={t('case-history:details.assistanceCaseNumber')}
                  value={caseDetails.assistanceCaseNumber ?? '-'}
                />
              </div>
              <Separator />
              <div className="grid gap-2">
                <h2 className="text-lg mb-2">
                  {t('case:new.situation.title')}
                </h2>
                <CaseDetailRow
                  label={t('case-history:details.situationType')}
                  value={t(
                    `case:situationTypes.${caseDetails.situation.situationType.id}`
                  )}
                />
                <CaseDetailRow
                  label={t('case-history:details.persons')}
                  value={caseDetails.situation.personCount.toString()}
                />
                <CaseDetailRow
                  label={t('case-history:details.trailer')}
                  value={
                    caseDetails.situation.hasTrailer
                      ? t('common:true')
                      : t('common:false')
                  }
                />
                <CaseDetailRow
                  label={t('case-history:details.animals')}
                  value={
                    caseDetails.situation.animalsOnBoard
                      ? t('common:true')
                      : t('common:false')
                  }
                />
              </div>
              <Separator />
              <div className="grid gap-2">
                <h2 className="text-lg mb-2">{t('case:new.vehicle.title')}</h2>
                <CaseDetailRow
                  label={t('case-history:details.registrationPlate')}
                  value={caseDetails.vehicle.registrationPlate}
                />
                <CaseDetailRow
                  label={t('case-history:details.mark')}
                  value={caseDetails.vehicle.make}
                />
                <CaseDetailRow
                  label={t('case-history:details.model')}
                  value={caseDetails.vehicle.model}
                />
                <CaseDetailRow
                  label={t('case-history:details.mileage')}
                  value={caseDetails.vehicle.mileage.toString()}
                />
                <CaseDetailRow
                  label={t('case-history:details.driveTrain')}
                  value={t(`case:driveTrain.${caseDetails.vehicle.driveTrain}`)}
                />
              </div>
              <Separator />
              <div className="grid gap-2">
                <h2 className="text-lg mb-2">{t('case:new.client.title')}</h2>
                <CaseDetailRow
                  label={t('case-history:details.clientName')}
                  value={caseDetails.client.fullName}
                />
                <CaseDetailRow
                  label={t('case-history:details.clientPhone')}
                  value={caseDetails.client.phoneNumber}
                />
              </div>
              <Separator />
              <div className="grid gap-2">
                <h2 className="text-lg mb-2">{t('case:new.location.title')}</h2>
                <CaseDetailRow
                  label={t('case-history:details.city')}
                  value={caseDetails.location.city}
                />
                <CaseDetailRow
                  label={t('case-history:details.streetAddress')}
                  value={caseDetails.location.address}
                />
                <CaseDetailRow
                  label={t('case-history:details.countryName')}
                  value={caseDetails.location.country.name}
                />
              </div>
              {caseDetails.comments && (
                <>
                  <Separator />
                  <div className="grid gap-2">
                    <h2 className="text-lg mb-2">
                      {t('case:new.details.title')}
                    </h2>
                    <div className="bg-muted/60 p-4 rounded-md">
                      <p className="text-sm">{caseDetails.comments}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <DialogFooter className="my-8">
              <Button
                onClick={() => {
                  setSearchParams('');
                  setOpen((state) => !state);
                }}
                type="submit"
              >
                {t('case-history:details.close')}
              </Button>
            </DialogFooter>
          </ScrollArea>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="flex items-center justify-center">
              {isLoading ? (
                <div className="flex">
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                  <h1>{t('case-history:details.loading')}</h1>
                </div>
              ) : (
                <h1>{t('case-history:details.noResult')}</h1>
              )}
            </DialogTitle>
          </DialogHeader>
        </DialogContent>
      )}
    </Dialog>
  );
}
