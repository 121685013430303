import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ui/components/form';
import { Control, FieldValues, Path, PathValue } from 'react-hook-form';
import { Textarea } from '@ui/components/textarea';

export interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder: string;
  description?: string;
  defaultValue?: PathValue<T, Path<T>>;
}

export function CaseFormTextAreaField<T extends FieldValues>(props: Props<T>) {
  const { name, control, label, placeholder, description } = props;
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea placeholder={placeholder} {...field} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
