import { z } from 'zod';
import { CaseFormTextField } from './case-form-text-field';
import { CaseFormShell } from './case-form-shell';
import { useFormContext } from 'react-hook-form';
import { CaseSchema, DriveTrain } from '@/backend/models/Case';
import { useLocale } from '@/hooks/use-language';
import { CaseFormRadioGroupField } from './case-form-radio-group-field';

export function VehicleSection() {
  const form = useFormContext<z.infer<typeof CaseSchema>>();
  const { t } = useLocale('case');

  const driveTrainOptions = Object.values(DriveTrain).map((value) => ({
    label: t(`case:driveTrain.${value}`),
    value: value,
  }));

  return (
    <CaseFormShell title={t('case:new.vehicle.title')}>
      <div className="grid gap-6">
        <CaseFormTextField
          {...form}
          name="vehicle.registrationPlate"
          label={t('case:new.vehicle.registrationPlate.label')}
          description={t('case:new.vehicle.registrationPlate.description')}
          placeholder={t('case:new.vehicle.registrationPlate.placeholder')}
        />
        <CaseFormTextField
          {...form}
          name="vehicle.make"
          label={t('case:new.vehicle.make.label')}
          description={t('case:new.vehicle.make.description')}
          placeholder={t('case:new.vehicle.make.placeholder')}
        />
        <CaseFormTextField
          {...form}
          name="vehicle.model"
          label={t('case:new.vehicle.model.label')}
          description={t('case:new.vehicle.model.description')}
          placeholder={t('case:new.vehicle.model.placeholder')}
        />
        <CaseFormTextField
          {...form}
          name="vehicle.mileage"
          label={t('case:new.vehicle.mileage.label')}
          description={t('case:new.vehicle.mileage.description')}
          placeholder={t('case:new.vehicle.mileage.placeholder')}
        />
        <CaseFormRadioGroupField
          {...form}
          name="vehicle.driveTrain"
          label={t('case:new.vehicle.driveTrain.label')}
          description={t('case:new.vehicle.driveTrain.description')}
          tooltip={t('case:driveTrainTooltip')}
          options={driveTrainOptions}
        />
      </div>
    </CaseFormShell>
  );
}
