"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpenseClaimAssistanceResponseSchema = exports.InvoiceBaseSchema = exports.ExpenseClaimFormCreateSubmissionEvent = exports.ExpenseClaimFormCreateInvoiceBaseEvent = exports.ExpenseClaimFormRequestSchema = void 0;
const zod_1 = require("zod");
exports.ExpenseClaimFormRequestSchema = zod_1.z.object({
    registrationPlate: zod_1.z.string(),
    date: zod_1.z.coerce.date(),
});
exports.ExpenseClaimFormCreateInvoiceBaseEvent = 'ExpenseClaimForm.InvoiceBase.Create';
exports.ExpenseClaimFormCreateSubmissionEvent = 'ExpenseClaimForm.Submission.Create';
// this schema is used when initializing the invoice to database
exports.InvoiceBaseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    assistanceCaseNumber: zod_1.z.string(),
    vehicleRegistrationPlate: zod_1.z.string(),
    incidentDate: zod_1.z.string(),
});
exports.ExpenseClaimAssistanceResponseSchema = exports.InvoiceBaseSchema.pick({
    vehicleRegistrationPlate: true,
    assistanceCaseNumber: true,
    createdAt: true,
});
