import { cn } from '@ui/lib/utils';
import { MainNavItem } from '@/types';
import { Link, useLocation } from 'react-router-dom';
import logoDark from '@/assets/img/hertz_logo_dark.svg';
import logoLight from '@/assets/img/hertz_logo_light.svg';
import { useTheme } from '@/hooks/use-theme';
import { useLocale } from '@/hooks/use-language';

interface MainNavProps {
  items?: MainNavItem[];
}

export function MainNav({ items }: MainNavProps) {
  const location = useLocation();
  const { theme, systemTheme } = useTheme();
  const { lang } = useLocale();

  return (
    <div className="hidden md:flex gap-6 md:gap-10">
      <Link
        to={`/${lang}/case-history`}
        className="hidden items-center space-x-2 md:flex"
      >
        {theme === 'light' ||
        (theme === 'system' && systemTheme === 'light') ? (
          <img src={logoDark} alt="" className="h-8 -my-3"></img>
        ) : (
          <img src={logoLight} alt="" className="h-8 -my-3"></img>
        )}
      </Link>
      {items?.length ? (
        <nav className="hidden gap-6 md:flex">
          {items?.map((item) => (
            <Link
              key={item.title}
              to={item.disabled ? '#' : item.href}
              className={cn(
                'flex items-center text-lg font-semibold text-slate-600 sm:text-sm',
                item.href.startsWith(`/${location.pathname ?? ''}`) &&
                  'text-slate-900',
                item.disabled && 'cursor-not-allowed opacity-80'
              )}
            >
              {item.title}
            </Link>
          ))}
        </nav>
      ) : null}
    </div>
  );
}
