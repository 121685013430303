"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roleDisplayLabel = exports.externalRoles = exports.internalRoles = void 0;
exports.internalRoles = ['assistance', 'admin'];
exports.externalRoles = [
    'dealer',
    'inspector',
    'volunteer',
];
exports.roleDisplayLabel = Object.freeze({
    assistance: 'Asiakaspalvelija',
    admin: 'Pääkäyttäjä',
    dealer: 'Myyjä',
    inspector: 'Tarkastaja',
    volunteer: 'Vapaaehtoinen tiepalvelija',
});
