"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vagServiceChannels = exports.replacementCarTermsText = exports.maxDaysLabel = exports.serviceChannelSchema = exports.replacementCarCategoriesSchema = exports.BillingPayerSchema = exports.BillingPayer = exports.maxDaysUnitSchema = exports.MaxDaysUnit = void 0;
const zod_1 = require("zod");
exports.MaxDaysUnit = {
    DAYS: 'Days',
    WORKING_DAYS: 'WorkingDays',
};
exports.maxDaysUnitSchema = zod_1.z.nativeEnum(exports.MaxDaysUnit);
exports.BillingPayer = {
    AL: 'AL',
    CUSTOMER: 'Asiakas',
    REPAIR_SHOP: 'Korjaamo',
    OTHER: 'Muu',
};
exports.BillingPayerSchema = zod_1.z.nativeEnum(exports.BillingPayer);
exports.replacementCarCategoriesSchema = zod_1.z.union([
    zod_1.z.literal('A'),
    zod_1.z.literal('B'),
    zod_1.z.literal('C'),
    zod_1.z.literal('D'),
    zod_1.z.literal('E'),
    zod_1.z.literal('F'),
    zod_1.z.literal('Same'), // Same category as customers own car.
]);
const replacementCarTermsSchema = zod_1.z.object({
    maxCategory: exports.replacementCarCategoriesSchema,
    maxRentalDays: zod_1.z.number().or(zod_1.z.string()),
    maxDaysUnit: exports.maxDaysUnitSchema,
    deliveryCosts: zod_1.z.boolean(),
    returnCosts: zod_1.z.boolean(),
    specialCosts: zod_1.z.boolean(),
    info: zod_1.z.string().optional(),
    operatorInstructions: zod_1.z.string().optional(),
    billingPayer: exports.BillingPayerSchema,
    billing: zod_1.z.string(), // If billingPayer is 'Muu', this should have the description.
});
exports.serviceChannelSchema = zod_1.z.object({
    _id: zod_1.z.string().optional(),
    type: zod_1.z.string(),
    billing: zod_1.z.string().optional(),
    vvAuto: zod_1.z.boolean().optional(),
    inArcReport: zod_1.z.boolean().optional(),
    arcId: zod_1.z.string().optional(),
    ltInsurance: zod_1.z.boolean().optional(),
    disabledForDealers: zod_1.z.boolean().optional(),
    bmwVoc: zod_1.z.boolean().optional(),
    endMileageRequired: zod_1.z.boolean().optional(),
    sparxInit: zod_1.z.boolean().optional(),
    alPlusOrPremium: zod_1.z.boolean().optional(),
    replacementCar: zod_1.z.boolean().optional(),
    replacementCarTerms: replacementCarTermsSchema.optional(),
    serviceRightRequired: zod_1.z.boolean().optional(),
});
exports.maxDaysLabel = Object.freeze({
    Days: 'Vuorokautta',
    WorkingDays: 'Arkipäivää',
});
const categoryText = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    Same: 'Sama kuin oma',
};
const dayUnitText = {
    Days: 'vuorokautta',
    WorkingDays: 'arkipäivää',
};
function replacementCarTermsText(terms, isLtInsurance, operatorMessage) {
    var _a;
    if (!terms) {
        return '';
    }
    else if (isLtInsurance) {
        return `${operatorMessage ? operatorMessage + ' ' : ''}${(_a = terms.info) !== null && _a !== void 0 ? _a : ''}`;
    }
    else {
        // Must be done either as one line or with array join because multiline strings add line breaks
        return [
            `max.${categoryText[terms.maxCategory]}`,
            `max.${terms.maxRentalDays} ${dayUnitText[terms.maxDaysUnit]}`,
            `toimitusmaksu: ${terms.deliveryCosts ? 'kyllä' : 'ei'}`,
            `jättömaksu: ${terms.returnCosts ? 'kyllä' : 'ei'}`,
            `erityiskustannukset: ${terms.specialCosts ? 'kyllä' : 'ei'}`,
            terms.info ? `lisätiedot: ${terms.info}` : '',
        ].join(', ');
    }
}
exports.replacementCarTermsText = replacementCarTermsText;
/*
  Below service channels are used for vag reports and deciding, whether to show "Ei liikkumisturvaa"
  button. As of 10/2023 we want following service channels to be included to report:

  * Audi Liikkumisturva
  * Seat Assitance
  * VW Liikkumisturva
  * VW HYA Liikkumisturva
  * Cupra Assistance
 */
exports.vagServiceChannels = ['1643', '4052', '4049', '4054', '5302'];
