import * as React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components/alert-dialog';
import { buttonVariants } from '@ui/components/button';
import { DropdownMenuItem } from '@ui/components/dropdown-menu';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';

interface CaseCancelAlertProps {
  disabled?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  cancelCase: () => void;
}

export function CaseCancelAlert({
  open,
  setOpen,
  isLoading,
  cancelCase,
  disabled,
}: CaseCancelAlertProps) {
  const { t } = useLocale('case');

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <DropdownMenuItem
        disabled={disabled}
        className="p-0"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <AlertDialogTrigger className="flex w-full cursor-default items-center px-2 py-1.5">
          <span className="text-destructive">{t('case:actions.cancel')}</span>
        </AlertDialogTrigger>
      </DropdownMenuItem>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('case:dialog.cancel.title')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('case:dialog.cancel.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('case:dialog.cancel.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: 'destructive' })}
            onClick={(event) => {
              event.preventDefault();
              cancelCase();
            }}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.close className="mr-2 h-4 w-4" />
            )}
            {t('case:dialog.cancel.action')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
