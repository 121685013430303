import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { cn } from '@ui/lib/utils';
import { Button, buttonVariants } from '@ui/components/button';
import { Icons } from '@ui/components/icons';
import { Label } from '@ui/components/label';
import { Input } from '@ui/components/input';
import { useRegisterUrls } from '@/hooks/use-auth';
import { useLocale } from '@/hooks/use-language';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

type UserRegisterFormProps = React.HTMLAttributes<HTMLDivElement>;

const UserAuthSchema = z.object({
  email: z.string().email(),
  name: z.string(),
});

type FormData = z.infer<typeof UserAuthSchema>;

export function UserRegisterForm({
  className,
  ...props
}: UserRegisterFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(UserAuthSchema),
  });

  const { t, lang } = useLocale('auth');

  const registerUrls = useRegisterUrls();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const error = searchParams.get('error');
  const timestamp = searchParams.get('timestamp');

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (error && timestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - Number(timestamp) < 30000) {
        timeout = setTimeout(() => {
          switch (error) {
            case 'no_account':
              return toast.error(t('auth:register.error.noAccount'), {
                position: 'bottom-right',
                duration: 8000,
              });
            default:
              return toast.error(t('auth:register.error.unknown'), {
                position: 'bottom-right',
                duration: 8000,
              });
          }
        }, 100);
      }
    }

    return () => clearTimeout(timeout);
  }, [error, timestamp, t]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);

    try {
      location.href = registerUrls.email(data.email, data.name);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
      return toast('Kirjautuminen epäonnistui. Yritä uudelleen.');
    }
  };

  return (
    <div className={cn('grid gap-4', className)} {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="name">
              {t('auth:register.helpers.name')}
            </Label>
            <Input
              id="name"
              placeholder={t('auth:register.namePlaceholder')}
              type="string"
              autoCapitalize="none"
              autoCorrect="off"
              disabled={isLoading}
              {...register('name')}
            />
            {errors?.email && (
              <p className="px-1 text-xs text-red-600">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              {t('auth:login.helpers.email')}
            </Label>
            <Input
              id="email"
              placeholder={t('auth:register.emailPlaceholder')}
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              {...register('email')}
            />
            {errors?.email && (
              <p className="px-1 text-xs text-red-600">
                {errors.email.message}
              </p>
            )}
          </div>
          <Button className={cn(buttonVariants())} disabled={isLoading}>
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            {t('auth:register.action')}
          </Button>
        </div>
      </form>
      <div className="flex justify-center text-sm gap-1">
        <span className="bg-background">
          {t('auth:register.helpers.alreadyRegistered')}
        </span>
        <a href={`/${lang}/auth/login`} className="underline">
          {t('auth:code.action')}
        </a>
      </div>
    </div>
  );
}
