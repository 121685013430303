import { AuthContext } from '@/context/auth';
import * as React from 'react';

function useAuth() {
  const result = React.useContext(AuthContext);
  if (!result) throw new Error('useAuth must be used within an AuthProvider');
  return result;
}

export function useUser() {
  return useAuth().user;
}

export function useRefreshUser() {
  return useAuth().refreshContext;
}

export function useLogout() {
  return useAuth().logout;
}

export function useLoginUrls() {
  return useAuth().loginUrls;
}

export function useRegisterUrls() {
  return useAuth().registerUrls;
}
