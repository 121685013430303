import { CaseStatus } from '@/backend/models/Case';
import { Icons } from '@ui/components/icons';

interface StatusType {
  value: CaseStatus;
  icon: keyof typeof Icons;
}

export const statuses: StatusType[] = [
  {
    value: CaseStatus.CREATED,
    icon: 'circle',
  },
  {
    value: CaseStatus.WAITING_PROCESSING,
    icon: 'clock',
  },
  {
    value: CaseStatus.IN_PRORGESS,
    icon: 'clock',
  },
  {
    value: CaseStatus.ASSISTANCE_ON_THE_WAY,
    icon: 'checkCircle',
  },
  {
    value: CaseStatus.READY,
    icon: 'checkCircle',
  },
  {
    value: CaseStatus.CANCELLED,
    icon: 'xCircle',
  },
  {
    value: CaseStatus.CANCELLATION_FAILED,
    icon: 'xCircle',
  },
  {
    value: CaseStatus.CANCELLATION_REQUESTED,
    icon: 'xCircle',
  },
  {
    value: CaseStatus.SENDING_FAILED,
    icon: 'xCircle',
  },
];
