import { Outlet } from 'react-router-dom';
import { NewCaseHeader } from './new-case-header';
import { ThemeToggle } from '@/components/layout/theme-toggle';
import { LanguageToggle } from '@/components/layout/language-toggle';

export function NewCaseLayout() {
  return (
    <div className="min-h-dvh flex flex-col">
      <NewCaseHeader />
      <main className="flex justify-center min-h-[calc(100vh-5.5rem-6rem)]">
        <div className="w-full max-w-[500px] mx-4">
          <Outlet />
        </div>
      </main>
      <footer className="py-6 md:px-8 md:py-0">
        <div className="container max-w-none flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row">
          <p className="text-center text-sm leading-loose text-muted-foreground md:text-left">
            {/* here is a placeholder for text if it's wanted to add */}
          </p>
          <div className="flex items-center gap-2">
            <ThemeToggle />
            <LanguageToggle />
          </div>
        </div>
      </footer>
    </div>
  );
}
