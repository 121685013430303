import { Table } from '@tanstack/react-table';

import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';

interface CaseTableViewOptionsProps<TData> {
  table: Table<TData>;
}

export function CaseTableViewOptions<TData>({
  table,
}: CaseTableViewOptionsProps<TData>) {
  const { t } = useLocale('case-history');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="ml-auto hidden h-8 lg:flex"
        >
          <Icons.settings2 className="mr-2 h-4 w-4" />
          {t('case-history:showColumns')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[175px]">
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== 'undefined' && column.getCanHide()
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {t(`case-history:${column.id}`)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
