import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ui/components/form';
import { Control, FieldValues, Path } from 'react-hook-form';
import { RadioGroup, RadioGroupItem } from '@ui/components/radio-group';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@ui/components/tooltip';
import { Icons } from '@ui/components/icons';

export interface RadioGroupOption {
  label: string;
  value: string;
}

export interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  description?: string;
  tooltip?: string;
  options: RadioGroupOption[];
  onSelect?: (value: string) => void;
}

export function CaseFormRadioGroupField<T extends FieldValues>(
  props: Props<T>
) {
  const { name, control, label, description, tooltip, options, onSelect } =
    props;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="space-y-3">
          <div className="flex items-center">
            <FormLabel>{label}</FormLabel>
            {tooltip && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger type="button" className="ml-2 -my-1">
                    <Icons.info className="h-4 w-4" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{tooltip}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          <FormControl>
            <RadioGroup
              onValueChange={onSelect ? onSelect : field.onChange}
              defaultValue={field.value.toString()}
              className="flex flex-col space-y-1"
            >
              {options.map((option) => (
                <FormItem
                  key={option.value}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={option.value} />
                  </FormControl>
                  <FormLabel className="font-normal">{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
