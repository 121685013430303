import { Case, CaseSchema, SituationType } from '@/backend/models/Case';
import { CaseFormShell } from './case-form-shell';
import { useLocale } from '@/hooks/use-language';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { CaseFormComboBoxField } from './case-form-combo-box-field';
import { mockCases } from '@/backend/test/utils/mockData';
import { Button } from '@ui/components/button';
import { CaseFormSelectField } from './case-form-select-field';

export function SituationSection() {
  const form = useFormContext<z.infer<typeof CaseSchema>>();
  const { t } = useLocale('case');

  const situationTypes = Object.keys(SituationType).map((key) => ({
    value: key,
    label: t(`case:situationTypes.${key}`),
  }));

  const onSituationTypeSelect = (value: string) => {
    const key = situationTypes.find(
      (type) => type.label.toLowerCase() === value
    )!.value;

    const situationType = Object.entries(SituationType).find(
      (entry) => entry[0].toLowerCase() === key
    );

    form.setValue('situation.situationType.id', situationType![0]);
    form.setValue('situation.situationType.type', situationType![1]);
  };

  const mode = import.meta.env.MODE;

  const handleTestData = () => {
    const orderCase: Case = mockCases[0];

    for (const [key, value] of Object.entries(orderCase)) {
      if (value && key !== 'id')
        form.setValue(`${key as keyof Case}`, value, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
    }
  };

  return (
    <CaseFormShell className="mt-4">
      {mode === 'development' && (
        <Button type="button" onClick={handleTestData} className="w-full mb-4">
          Täytä lomake testidatalla
        </Button>
      )}
      <div className="grid gap-6">
        <CaseFormComboBoxField
          {...form}
          name="situation.situationType.id"
          label={t('case:new.situation.situationType.label')}
          placeholder={t('case:new.situation.situationType.placeholder')}
          description={t('case:new.situation.situationType.description')}
          options={situationTypes}
          onSelect={onSituationTypeSelect}
          className="w-full"
        />
        <CaseFormSelectField
          {...form}
          name="situation.personCount"
          label={t('case:new.situation.personCount.label')}
          placeholder={t('case:new.situation.personCount.placeholder')}
          description={t('case:new.situation.personCount.description')}
          options={Array.from({ length: 8 }, (_, i) =>
            i === 0
              ? {
                  label:
                    (i + 1).toString() +
                    ' ' +
                    t('case:new.situation.personCount.person'),
                  value: (i + 1).toString(),
                }
              : {
                  label:
                    (i + 1).toString() +
                    ' ' +
                    t('case:new.situation.personCount.persons'),
                  value: (i + 1).toString(),
                }
          )}
          className="w-[125px]"
        />
      </div>
    </CaseFormShell>
  );
}
