import { MainNav } from './main-nav';
import { MobileNav } from './mobile-nav';
import { UserAccountNav } from './user-account-nav';

export function SiteHeader() {
  return (
    <header className="supports-backdrop-blur:bg-background-secondary/50 sticky top-0 z-40 w-full border-b bg-background-secondary backdrop-blur supports-[backdrop-filter]:bg-background-secondary/50">
      <div className="h-18 container px-4 md:px-8 max-w-none flex items-center justify-between py-4">
        <MainNav />
        <MobileNav />
        <div className="flex items-center justify-between space-x-2 sm:space-x-4 md:justify-end md:flex-1">
          <nav className="flex items-center space-x-1">
            <UserAccountNav />
          </nav>
        </div>
      </div>
    </header>
  );
}
