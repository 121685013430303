import { ColumnDef } from '@tanstack/react-table';

import { User, UserSchema } from '@/backend/models/User';
import { RejectedUserTableRowActions } from './rejected-user-table-row-actions';
import { UserRow } from '@/pages/dashboard/users/user-row';

export const createRejectedUserColumns = (): ColumnDef<User>[] => [
  {
    id: 'data',
    cell: ({ row }) => {
      const user = UserSchema.parse(row.original);

      return <UserRow user={user} />;
    },
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, _id, value) => {
      const user = UserSchema.parse(row.original);
      return user.name.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => (
      <div className="flex justify-end">
        <RejectedUserTableRowActions row={row} />
      </div>
    ),
  },
];
