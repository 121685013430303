import { useLocale } from '@/hooks/use-language';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components/alert-dialog';
import { DropdownMenuItem } from '@ui/components/dropdown-menu';
import { buttonVariants } from '@ui/components/button';
import { Icons } from '@ui/components/icons';

interface UserRejectAlertProps {
  isLoading: boolean;
  rejectUser: () => Promise<void>;
}

export function UserRejectAlert({
  isLoading,
  rejectUser,
}: UserRejectAlertProps) {
  const { t } = useLocale('users');

  return (
    <AlertDialog>
      <DropdownMenuItem
        className="p-0"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <AlertDialogTrigger className="w-full px-2 py-1.5 flex items-center cursor-default">
          <span className="text-destructive">{t('users:actions.reject')}</span>
        </AlertDialogTrigger>
      </DropdownMenuItem>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('users:dialog.reject.title')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('users:dialog.reject.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('users:dialog.reject.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: 'destructive' })}
            onClick={async (event) => {
              event.preventDefault();
              await rejectUser();
            }}
          >
            {isLoading && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            {t('users:dialog.reject.action')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
