import * as React from 'react';
import { Button } from '@ui/components/button';
import { Icons } from '@ui/components/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';
import { useLogout, useRefreshUser, useUser } from '@/hooks/use-auth';
import { useLocale } from '@/hooks/use-language';
import { Avatar, AvatarFallback } from '@ui/components/avatar';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@ui/components/dialog';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { UserSchema } from '@/backend/models/User';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@ui/components/form';
import { Input } from '@ui/components/input';
import { trpc } from '@/lib/trpc';
import toast from 'react-hot-toast';
import { User, store } from '@/context/auth';

interface EditUserDialogProps {
  user: User;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserUpdateSchema = UserSchema.pick({ name: true });

export function EditUserDialog({ user, setDropdownOpen }: EditUserDialogProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const { t } = useLocale('users');

  const utils = trpc.useUtils();

  const refreshUserContext = useRefreshUser();

  const { mutate, isLoading } = trpc.user.update.useMutation({
    async onSuccess(data) {
      store.set({ user: { ...user, ...data } });
      refreshUserContext();
      await utils.user.invalidate();
      setOpen(false);
      setDropdownOpen(false);
      toast.success(t('users:updatedSuccessfully'));
    },
  });

  const form = useForm<z.infer<typeof UserUpdateSchema>>({
    resolver: zodResolver(UserUpdateSchema),
    defaultValues: {
      name: user.name,
    },
  });

  const onSubmit = async (values: z.infer<typeof UserUpdateSchema>) =>
    mutate({ ...user, ...values });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DropdownMenuItem
        className="p-0"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTrigger className="w-full px-2 py-1.5 flex items-center cursor-default">
          {t('users:actions.edit')}
        </DialogTrigger>
      </DropdownMenuItem>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4">
            <DialogHeader>
              <DialogTitle>{t('users:dialog.edit.title')}</DialogTitle>
              <DialogDescription>
                {t('users:dialog.edit.description')}
              </DialogDescription>
            </DialogHeader>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <FormLabel className="text-right">
                      {t('users:name')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        autoComplete="off"
                        className="col-span-3"
                        placeholder={user.name}
                        {...field}
                      />
                    </FormControl>
                  </div>
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={!form.formState.isValid} type="submit">
                {isLoading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                {t('users:actions.saveChanges')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export function UserAccountNav() {
  const [open, setOpen] = React.useState<boolean>(false);
  const logout = useLogout();
  const user = useUser();

  const { t } = useLocale('nav');

  if (!user) {
    return;
  }

  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarFallback className="bg-slate-200 dark:bg-slate-700">
              <Icons.user className="h-4 w-4 text-slate-700 dark:text-foreground" />
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex items-center justify-start gap-2 p-2">
          <div className="flex flex-col space-y-1 leading-none">
            <p className="font-medium">{user?.name}</p>
            <p className="text-sm">{user?.email}</p>
          </div>
        </div>
        <DropdownMenuSeparator />
        <EditUserDialog user={user} setDropdownOpen={setOpen} />
        <DropdownMenuItem
          onSelect={(event) => {
            event.preventDefault();
            logout();
          }}
        >
          {t('nav:signOut')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
