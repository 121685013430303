import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';
import { useTheme } from '@/hooks/use-theme';
import { Icons } from '@ui/components/icons';
import { cn } from '@ui/lib/utils';
import { useLocale } from '@/hooks/use-language';

export function ThemeToggle() {
  const { setTheme, theme } = useTheme();
  const { t } = useLocale('common');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Icons.sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Icons.moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">{t('common:theme.toggle')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className={cn(
            'hover:cursor-pointer text-muted-foreground/60 hover:text-foreground',
            theme === 'light' && 'text-foreground'
          )}
          onClick={() => setTheme('light')}
        >
          <Icons.sun className="h-4 w-4 mr-2" />
          {t('common:theme.light')}
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn(
            'hover:cursor-pointer text-muted-foreground/60 hover:text-foreground',
            theme === 'dark' && 'text-foreground'
          )}
          onClick={() => setTheme('dark')}
        >
          <Icons.moon className="h-4 w-4 mr-2" />
          {t('common:theme.dark')}
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn(
            'hover:cursor-pointer text-muted-foreground/60 hover:text-foreground',
            theme === 'system' && 'text-foreground'
          )}
          onClick={() => setTheme('system')}
        >
          <Icons.laptop className="h-4 w-4 mr-2" />
          {t('common:theme.system')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
