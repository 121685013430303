"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hereItemToSuggestion = exports.hereItemToMapItem = exports.hereItemToGeoLocation = exports.HereRouteMatrixError = void 0;
var HereRouteMatrixError;
(function (HereRouteMatrixError) {
    HereRouteMatrixError[HereRouteMatrixError["CALCULATION_SUCCESSFUL"] = 0] = "CALCULATION_SUCCESSFUL";
    HereRouteMatrixError[HereRouteMatrixError["GRAPH_DISCONNECTED"] = 1] = "GRAPH_DISCONNECTED";
    HereRouteMatrixError[HereRouteMatrixError["MATCHING_FAILED"] = 2] = "MATCHING_FAILED";
    HereRouteMatrixError[HereRouteMatrixError["ROUTE_CALCULATION_VIOLATION"] = 3] = "ROUTE_CALCULATION_VIOLATION";
    HereRouteMatrixError[HereRouteMatrixError["WAYPOINTS_OUTSIDE_REGION"] = 4] = "WAYPOINTS_OUTSIDE_REGION";
    HereRouteMatrixError[HereRouteMatrixError["UNKNOWN_ERROR"] = 99] = "UNKNOWN_ERROR";
})(HereRouteMatrixError || (exports.HereRouteMatrixError = HereRouteMatrixError = {}));
function hereItemToGeoLocation(item) {
    var _a, _b, _c, _d, _e, _f, _g;
    const address = item.address;
    return {
        type: 'Point',
        coordinates: [item.position.lng, item.position.lat],
        address: {
            label: address.label,
            country: address.countryCode,
            state: (_a = address.state) !== null && _a !== void 0 ? _a : '',
            county: (_b = address.county) !== null && _b !== void 0 ? _b : '',
            city: (_c = address.city) !== null && _c !== void 0 ? _c : '',
            district: (_d = address.district) !== null && _d !== void 0 ? _d : '',
            street: (_e = address.street) !== null && _e !== void 0 ? _e : '',
            houseNumber: (_f = address === null || address === void 0 ? void 0 : address.houseNumber) !== null && _f !== void 0 ? _f : '',
            postalCode: (_g = address === null || address === void 0 ? void 0 : address.postalCode) !== null && _g !== void 0 ? _g : '',
        },
    };
}
exports.hereItemToGeoLocation = hereItemToGeoLocation;
function hereItemToMapItem(item) {
    const mapItem = {
        type: item.resultType,
        title: item.title,
        label: item.address.label,
        country: item.address.countryCode,
        county: item.address.county,
        state: item.address.state,
        city: item.address.city,
        district: item.address.district,
        postalCode: item.address.postalCode,
        street: item.address.street,
        houseNumber: item.address.houseNumber,
        latitude: item.position.lat,
        longitude: item.position.lng,
    };
    return mapItem;
}
exports.hereItemToMapItem = hereItemToMapItem;
function hereItemToSuggestion(item) {
    return {
        id: item.id,
        title: item.title,
        label: item.address.label,
        lat: item.position.lat,
        lon: item.position.lng,
    };
}
exports.hereItemToSuggestion = hereItemToSuggestion;
