import { Table } from '@tanstack/react-table';

import { Input } from '@ui/components/input';
import { useLocale } from '@/hooks/use-language';

interface RejectedUserTableToolbarProps<TData> {
  table: Table<TData>;
  disabled: boolean;
}

export function RejectedUserTableToolbar<TData>({
  table,
  disabled,
}: RejectedUserTableToolbarProps<TData>) {
  const { t } = useLocale('users');

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          disabled={disabled}
          placeholder={t('users:search')}
          value={(table.getColumn('data')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('data')?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
      </div>
    </div>
  );
}
