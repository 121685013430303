import * as React from 'react';
import { Buffer } from 'buffer';
import { UserRole } from '@/backend/models/User';

export type User = {
  [x: string]: unknown;
  id: string;
  email: string;
  token: string;
  name: string;
  role: UserRole;
};

type AuthContextType = {
  user: User | null;
  loginUrls: {
    email: (email: string, lang: 'fi' | 'en', newCode: boolean) => string;
  };
  registerUrls: {
    email: (email: string, name: string) => string;
  };
  logout: () => void;
  refreshContext: () => void;
};

function decodeToken(input: { token: string }): User {
  const [, payloadEncoded] = input.token.split('.');
  const payload = JSON.parse(
    Buffer.from(
      payloadEncoded.replace(/-/g, '+').replace(/_/g, '/'),
      'base64'
    ).toString()
  );

  const user = {
    ...payload.properties,
    id: payload.properties.userId,
    token: input.token,
  };

  // we would prefer to use id instead of userId on the client side
  delete user.userId;

  return user;
}

function loginUrl(input: {
  provider: 'email';
  lang: 'fi' | 'en';
  email: string;
  newCode: boolean;
}) {
  const params = new URLSearchParams({
    client_id: 'web',
    redirect_uri: location.origin + '/fi/auth/callback',
    response_type: 'token',
    provider: input.provider,
    email: input.email,
    lang: input.lang,
    newCode: input.newCode ? 'true' : 'false',
  });

  const url =
    import.meta.env.VITE_AUTH_URL + '/email/authorize?' + params.toString();
  return url;
}

function registerUrl(input: {
  provider: 'google' | 'email';
  email: string;
  name: string;
}) {
  const params = new URLSearchParams({
    client_id: 'web',
    redirect_uri: location.origin + '/fi/auth/callback',
    response_type: 'token',
    provider: input.provider,
    email: input.email,
    name: input.name,
  });

  const url =
    import.meta.env.VITE_AUTH_URL + '/email/authorize?' + params.toString();
  return url;
}

const loginUrls = {
  email: (email: string, lang: 'fi' | 'en', newCode: boolean) =>
    loginUrl({ provider: 'email', email, newCode, lang }),
};

const registerUrls = {
  email: (email: string, name: string) =>
    registerUrl({ provider: 'email', email: email, name: name }),
};

export const store = {
  get() {
    const raw = localStorage.getItem('user');
    if (!raw) return null;
    return JSON.parse(raw) as User;
  },
  set(input: { user: User }) {
    return localStorage.setItem('user', JSON.stringify(input.user));
  },
  remove() {
    return localStorage.removeItem('user');
  },
};

function logout() {
  store.remove();
  location.href = location.origin;
}

export const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
);

export type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider(props: AuthProviderProps) {
  // this is a hack to refresh user after data is changed
  const [refresh, setRefresh] = React.useState<number>(1);

  const user = React.useMemo<User | null>(() => {
    if (refresh > 0) {
      const fragment = new URLSearchParams(location.hash.substring(1));
      const access_token = fragment.get('access_token');
      if (access_token && !store.get()) {
        // handling an auth callback
        const user = decodeToken({ token: access_token });
        store.set({ user });
        return user;
      }
      return store.get();
    }

    return null;
  }, [refresh]);

  const refreshContext = () => {
    setRefresh((prev) => prev + 1);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loginUrls,
        registerUrls,
        logout,
        refreshContext,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
