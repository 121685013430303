import * as React from 'react';
import { Row, Table } from '@tanstack/react-table';

import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';

import { Case, CaseSchema, CaseStatus } from '@/backend/models/Case';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';
import { trpc } from '@/lib/trpc';
import toast from 'react-hot-toast';
import { CaseDeleteAlert } from './case-delete-alert';
import { useSearchParams } from 'react-router-dom';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@ui/components/tooltip';
import { CaseCancelAlert } from './case-cancel-alert';

interface CaseTableRowActionsProps<TData> {
  table: Table<TData>;
  row: Row<TData>;
}

export function CaseTableRowActions<TData>({
  table,
  row,
}: CaseTableRowActionsProps<TData>) {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<Row<TData>[]>([]);

  const [, setSearchParams] = useSearchParams();

  const { t } = useLocale(['case', 'case-history']);
  const orderCase = CaseSchema.parse(row.original);

  const utils = trpc.useUtils();

  const { mutate: updateMutate, isLoading: isUpdating } =
    trpc.case.update.useMutation({
      async onSuccess() {
        await utils.case.invalidate();
        // remove all row selections
        table.toggleAllPageRowsSelected(false);
        toast.success(t('case:updatedSuccessfully'));
      },
      onError: () => {
        toast.error(t('case:unexpectedError'));
      },
    });

  const { mutate: cancelMutate, isLoading: isCancelling } =
    trpc.case.cancel.useMutation({
      async onSuccess() {
        await utils.case.invalidate();
        setDialogOpen(false);
        toast.success(t('case:cancelSentSuccessfully'));
      },
      onError: () => {
        toast.error(t('case:unexpectedError'));
      },
    });

  const { mutate: deleteMutate, isLoading } = trpc.case.delete.useMutation({
    async onSuccess() {
      await utils.case.invalidate();
      // remove all row selections
      table.toggleAllPageRowsSelected(false);
      toast.success(t('case:deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('case:unexpectedError'));
    },
  });

  const { mutate: deleteManyMutate, isLoading: isDeleteManyLoading } =
    trpc.case.deleteMany.useMutation({
      async onSuccess() {
        await utils.case.invalidate();
        // remove all row selections
        table.toggleAllPageRowsSelected(false);
        toast.success(t('case:deletedManySuccessfully'));
      },
      onError: () => {
        toast.error(t('case:unexpectedError'));
      },
    });

  const deleteCase = async () => deleteMutate(orderCase.id!);
  const deleteManyCases = async () => {
    const caseIds = selectedRows.map(
      (row) => CaseSchema.parse(row.original).id!
    );
    deleteManyMutate(caseIds);
  };

  const updateCase = async (newValues: Partial<Case>) =>
    updateMutate({ ...orderCase, ...newValues });

  const handleCancelCase = () => {
    cancelMutate(orderCase.id!);
  };

  const getAvailableStatuses = (): CaseStatus[] => {
    const currentlyAvailableOptions: CaseStatus[] = [
      CaseStatus.CREATED,
      CaseStatus.WAITING_PROCESSING,
      CaseStatus.IN_PRORGESS,
      CaseStatus.SENDING_FAILED,
      CaseStatus.READY,
      CaseStatus.CANCELLED,
    ];

    return Object.values(CaseStatus).filter((status) =>
      currentlyAvailableOptions.includes(status)
    );
  };

  return (
    <DropdownMenu
      modal={false}
      onOpenChange={(open) => {
        if (open) {
          const selected = table.getSelectedRowModel().rows;
          setSelectedRows(selected);
        }
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          disabled={isUpdating}
        >
          {isUpdating ? (
            <Icons.spinner className="h-4 w-4 animate-spin" />
          ) : (
            <Icons.more className="h-4 w-4"></Icons.more>
          )}
          <span className="sr-only">{t('case-history:actions.openMenu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuItem
          onSelect={() => {
            setSearchParams({ caseId: orderCase.id! });
          }}
        >
          {t('case:actions.open')}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Status</DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuRadioGroup value={orderCase.status}>
              {getAvailableStatuses().map((status) => (
                <DropdownMenuRadioItem
                  key={status}
                  value={status}
                  onSelect={() => updateCase({ ...orderCase, status })}
                >
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger className="flex flex-1 cursor-default">
                        {t(`case:status.${status}`)}
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('case:actions.tooltip')}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <CaseCancelAlert
          open={dialogOpen}
          disabled={orderCase.status === CaseStatus.CANCELLED}
          setOpen={setDialogOpen}
          cancelCase={handleCancelCase}
          isLoading={isLoading || isCancelling}
        />
        <DropdownMenuSeparator />
        <CaseDeleteAlert
          row={row}
          deleteCase={deleteCase}
          deleteManyCases={deleteManyCases}
          isLoading={isLoading || isDeleteManyLoading}
          selectedRows={selectedRows}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
