import { z } from 'zod';

export const UserRole = {
  ADMIN: 'admin',
  USER: 'regular-user',
} as const;
export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export const UserRoleSchema = z.nativeEnum(UserRole);

export const UserStatus = {
  UNCONFIRMED: 'unconfirmed',
  REJECTED: 'rejected',
  CONFIRMED: 'confirmed',
} as const;
export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

export const UserSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1).trim(),
  role: z.nativeEnum(UserRole),
  email: z.string().email().trim(),
  status: z.nativeEnum(UserStatus).optional(),
});

export type User = z.infer<typeof UserSchema>;
