import * as React from 'react';
import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';
import { Icons } from '@ui/components/icons';
import { useLocale } from '@/hooks/use-language';

interface UserTableOptionsProps {
  setRejectedUsersOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function UserTableOptions({
  setRejectedUsersOpen,
}: UserTableOptionsProps) {
  const { t } = useLocale('users');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 w-8 p-0">
          <Icons.more className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={() => setRejectedUsersOpen((state) => !state)}
        >
          <Icons.rejectedUser className="h-4 w-4 mr-2" />
          {t('users:options.showRejected')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
