import { useLocale } from '@/hooks/use-language';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components/alert-dialog';
import { DropdownMenuItem } from '@ui/components/dropdown-menu';
import { buttonVariants } from '@ui/components/button';
import { Icons } from '@ui/components/icons';
import { Row } from '@tanstack/react-table';

interface CaseDeleteAlertProps<TData> {
  row: Row<TData>;
  selectedRows: Row<TData>[];
  isLoading: boolean;
  deleteCase: () => Promise<void>;
  deleteManyCases: () => Promise<void>;
}

export function CaseDeleteAlert<TData>({
  row,
  selectedRows,
  isLoading,
  deleteCase,
  deleteManyCases,
}: CaseDeleteAlertProps<TData>) {
  const { t } = useLocale('case');

  const isSelected = row.getIsSelected();

  return (
    <AlertDialog>
      <DropdownMenuItem
        className="p-0"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <AlertDialogTrigger className="w-full px-2 py-1.5 flex items-center cursor-default">
          {isSelected && selectedRows.length > 1 ? (
            <span className="text-destructive">
              {t('case:actions.deleteMany', { count: selectedRows.length })}
            </span>
          ) : (
            <span className="text-destructive">{t('case:actions.delete')}</span>
          )}
        </AlertDialogTrigger>
      </DropdownMenuItem>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('case:dialog.delete.title')}</AlertDialogTitle>
          <AlertDialogDescription>
            {isSelected && selectedRows.length > 1
              ? t('case:dialog.delete.descriptionMany')
              : t('case:dialog.delete.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('case:dialog.delete.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: 'destructive' })}
            onClick={async (event) => {
              event.preventDefault();
              if (isSelected && selectedRows.length) {
                await deleteManyCases();
              } else {
                await deleteCase();
              }
            }}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.trash className="mr-2 h-4 w-4" />
            )}
            {isSelected && selectedRows.length > 1
              ? t('case:dialog.delete.actionMany', {
                  count: selectedRows.length,
                })
              : t('case:dialog.delete.action')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
