import { cn } from '@ui/lib/utils';

interface CaseFormShellProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

export function CaseFormShell(props: CaseFormShellProps) {
  return (
    <div className={cn('mt-2', props.className)}>
      {props.title && (
        <div className="grid gap-4 mb-4">
          <h2 className="font-semibold font-raleway leading-none tracking-tight text-2xl">
            {props.title}
          </h2>
          <div className="w-full border-b"></div>
        </div>
      )}
      {props.children}
    </div>
  );
}
