import * as React from 'react';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ui/components/form';
import { Control, FieldValues, Path } from 'react-hook-form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@ui/components/popover';
import { Button } from '@ui/components/button';
import { cn } from '@ui/lib/utils';
import { Icons } from '@ui/components/icons';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@ui/components/command';
import { useLocale } from '@/hooks/use-language';
import { ScrollArea } from '@ui/components/scroll-area';

export interface ComboBoxOption {
  label: string;
  value: string;
}

export interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder: string;
  description?: string;
  options: ComboBoxOption[];
  onSelect?: (value: string) => void;
  className?: string;
}

export function CaseFormComboBoxField<T extends FieldValues>(props: Props<T>) {
  const [open, setOpen] = React.useState<boolean>(false);

  const { t } = useLocale('common');

  const {
    name,
    control,
    label,
    placeholder,
    description,
    options,
    onSelect,
    className,
  } = props;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>{label}</FormLabel>
          <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    'w-[200px] justify-between font-normal px-3',
                    !field.value && 'text-muted-foreground',
                    className
                  )}
                >
                  {field.value
                    ? options.find(
                        (option) => option.value === field.value.toLowerCase()
                      )?.label
                    : placeholder}
                  <Icons.chevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className={cn(
                'popover-content-width-same-as-its-trigger p-0',
                className
              )}
            >
              <Command>
                <CommandInput placeholder={t('common:search')} />
                <CommandEmpty>{t('common:noResults')}</CommandEmpty>
                <ScrollArea>
                  <CommandGroup className="max-h-[40vh] overflow-y-auto">
                    {options.map((option, i) => (
                      <CommandItem
                        className="font-normal text-sm"
                        value={option.label}
                        key={option.value + i}
                        onSelect={(value) => {
                          if (onSelect) {
                            onSelect(value);
                          }

                          setOpen(false);
                        }}
                      >
                        <Icons.check
                          className={cn(
                            'mr-2 h-4 w-4',
                            field.value &&
                              option.value === field.value.toLowerCase()
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </ScrollArea>
              </Command>
            </PopoverContent>
          </Popover>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
