import * as React from 'react';
import { Row } from '@tanstack/react-table';

import { Button, buttonVariants } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';

import { Icons } from '@ui/components/icons';
import { UserRole, UserSchema, UserStatus } from '@/backend/models/User';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@ui/components/tooltip';
import { useLocale } from '@/hooks/use-language';
import { trpc } from '@/lib/trpc';
import toast from 'react-hot-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@ui/components/alert-dialog';
import { UserRejectAlert } from './user-reject-alert';
import { UserDeleteAlert } from './user-delete-alert';
import { useUser } from '@/hooks/use-auth';
import { checkUserDetailPermission } from '@/backend/utils/auth';

interface UserTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function UserTableRowActions<TData>({
  row,
}: UserTableRowActionsProps<TData>) {
  const [open, setOpen] = React.useState<boolean>(false);

  const currentUser = useUser();
  const user = UserSchema.parse(row.original);

  const { t, lang } = useLocale('users');

  const utils = trpc.useUtils();

  const { mutate: confirmMutate, isLoading: isConfirmLoading } =
    trpc.user.setUserStatus.useMutation({
      async onSuccess() {
        await utils.user.invalidate();
        toast.success(t('users:confirmedSuccessfully'));
      },
    });

  const { mutate: rejectMutate, isLoading: isRejectLoading } =
    trpc.user.setUserStatus.useMutation({
      async onSuccess() {
        await utils.user.invalidate();
        toast.success(t('users:rejectedSuccessfully'));
      },
    });

  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    trpc.user.update.useMutation({
      async onSuccess() {
        await utils.user.invalidate();
        toast.success(t('users:updatedSuccessfully'));
      },
    });

  const { mutate: deleteMutate, isLoading: isDeleteLoading } =
    trpc.user.delete.useMutation({
      async onSuccess() {
        await utils.user.getAll.refetch();
        toast.success(t('users:deletedSuccessfully'));
      },
    });

  const confirmUser = async () =>
    confirmMutate({
      userId: user.id!,
      status: UserStatus.CONFIRMED,
      emailLang: lang as 'fi' | 'en',
    });

  const rejectUser = async () =>
    rejectMutate({
      userId: user.id!,
      status: UserStatus.REJECTED,
      emailLang: lang as 'fi' | 'en',
    });

  const deleteUser = async () => deleteMutate(user.id!);

  const selectRole = async (role: UserRole) => updateMutate({ ...user, role });

  const isCurrentUser = user.id === currentUser?.id;
  const showActionButton =
    checkUserDetailPermission(
      { ...currentUser!, userId: currentUser!.id },
      user
    ) && !isCurrentUser;

  if (!showActionButton) {
    return null;
  }

  if (user.status === UserStatus.UNCONFIRMED) {
    return (
      <TooltipProvider>
        <div className="flex space-x-2">
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                onClick={confirmUser}
                className="flex h-8 w-8 p-0 bg-constructive hover:bg-constructive/80"
              >
                {isConfirmLoading ? (
                  <Icons.spinner className="h-4 w-4 animate-spin" />
                ) : (
                  <Icons.check className="h-4 w-4 text-constructive-foreground"></Icons.check>
                )}
                <span className="sr-only">{t('users:actions.confirm')}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent align="end">
              <p>{t('users:actions.confirm')}</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip delayDuration={0}>
            <AlertDialog>
              <TooltipTrigger asChild>
                <AlertDialogTrigger asChild>
                  <Button className="flex h-8 w-8 p-0 bg-destructive hover:bg-destructive/80">
                    {isRejectLoading ? (
                      <Icons.spinner className="h-4 w-4 animate-spin" />
                    ) : (
                      <Icons.close className="h-4 w-4 text-destructive-foreground"></Icons.close>
                    )}
                    <span className="sr-only">{t('users:actions.reject')}</span>
                  </Button>
                </AlertDialogTrigger>
              </TooltipTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t('users:dialog.reject.title')}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {t('users:dialog.reject.description')}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>
                    {t('users:dialog.reject.cancel')}
                  </AlertDialogCancel>
                  <AlertDialogAction
                    className={buttonVariants({ variant: 'destructive' })}
                    onClick={async (event) => {
                      event.preventDefault();
                      await rejectUser();
                    }}
                  >
                    {isRejectLoading && (
                      <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    {t('users:dialog.reject.action')}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <TooltipContent align="end">
              <p>{t('users:actions.reject')}</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>
    );
  }

  return (
    <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild disabled={isUpdateLoading}>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          {isUpdateLoading ? (
            <Icons.spinner className="h-4 w-4 animate-spin" />
          ) : (
            <Icons.more className="h-4 w-4"></Icons.more>
          )}
          <span className="sr-only">{t('users:actions.openMenu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            {t('users:actions.role')}
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuRadioGroup value={user.role}>
              {Object.values(UserRole).map((role) => (
                <DropdownMenuRadioItem
                  key={role}
                  value={role}
                  onSelect={() => selectRole(role)}
                >
                  {t(`users:role.${role}`)}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <UserRejectAlert isLoading={isRejectLoading} rejectUser={rejectUser} />
        <DropdownMenuSeparator />
        <UserDeleteAlert isLoading={isDeleteLoading} deleteUser={deleteUser} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
