import { useLocale } from '@/hooks/use-language';
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from '@ui/components/command';
import { Icons } from '@ui/components/icons';
import { WithId } from '@/backend/types';
import { Case } from '@/backend/models/Case';

export function CommandMenuCases({
  isLoading,
  data,
  onSelect,
}: {
  isLoading: boolean;
  data: WithId<Case>[];
  onSelect: (value: string) => void;
}) {
  const { t } = useLocale('nav');

  return (
    <>
      {isLoading && !data ? (
        <CommandEmpty>{t('nav:commandMenu.loading')}</CommandEmpty>
      ) : (
        <CommandEmpty>{t('nav:commandMenu.noResults')}</CommandEmpty>
      )}
      {data && data.length > 0 && (
        <CommandGroup heading={t('nav:cases')}>
          {data.map(({ id, vehicle: { registrationPlate } }) => {
            return (
              <CommandItem
                key={id}
                onSelect={onSelect}
                value={JSON.stringify({ id, registrationPlate })}
              >
                <div className="mr-2 flex h-4 w-4 items-center justify-center">
                  <Icons.circle className="h-3 w-3" />
                </div>
                {registrationPlate}
              </CommandItem>
            );
          })}
        </CommandGroup>
      )}
    </>
  );
}
