"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openReasonText = void 0;
exports.openReasonText = {
    inactive: 'Toimeton',
    noreply: 'Ei vastausta',
    rejected: 'Kieltäydytty',
    customerCancelled: 'Asiakas perui',
    manuallyOpened: 'Palautettu avoimeksi',
    newMobileCase: 'Uusi mobiilitehtävä',
    newBotCase: 'Uusi bottitehtävä',
    newSparxCase: 'Uusi sparx-tehtävä',
    newGarageCase: 'Uusi korjaamolomakkeen tehtävä',
    newRedgoCase: 'Uusi Redgo-korjaamolomakkeen tehtävä',
    newHertzCase: 'Uusi Hertz-korjaamolomakkeen tehtävä',
    repairShopReply: 'Vastaus korjaamolta',
    arcCompletionChanged: 'Toimenpidekoodi vaihtunut',
    sparxCaseNoteAdded: 'Uusi Sparx viesti vastaanotettu',
    sparxServiceNeedsAttention: 'Sparx palvelu vaatii toimia',
};
