"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LahiTapiolaInsuranceInfoSchema = exports.LahiTapiolaKaskoItemSchema = exports.LtReplacementVehicleDays = exports.LtInvoicingInformation = exports.ltAreaCompanies = void 0;
const zod_1 = require("zod");
exports.ltAreaCompanies = [
    'LähiTapiola Etelä Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Etelä-Pohjanmaa Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Etelärannikko Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Itä Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Kaakkois-Suomi Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Kainuu-Koillismaa Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Keski-Suomi Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Lappi Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Loimi-Häme Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Länsi-Suomi Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Pirkanmaa Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Pohjanmaa Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Pohjoinen Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Pääkaupunkiseutu Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Savo Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Savo-Karjala Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Uusimaa Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Varsinais-Suomi Keskinäinen Vakuutusyhtiö',
    'LähiTapiola Vellamo Keskinäinen Vakuutusyhtiö',
];
exports.LtInvoicingInformation = {
    CASH_CUSTOMER: 'Käteisasiakas',
    INSURANCE_CUSTOMER: 'Vakuutusasiakas',
    INSURANCE_NOT_VERIFIED: 'Vakuutustietoa ei voitu tarkistaa',
    COMPENSABILITY_NOT_VERIFIED: 'Korvattavuutta ei voitu tarkistaa',
};
exports.LtReplacementVehicleDays = {
    '1': 'Yksi vuorokausi',
    '2': 'Kaksi vuorokautta',
    '3': 'Kolme vuorokautta',
    '7': 'Seitsemän vuorokautta',
};
exports.LahiTapiolaKaskoItemSchema = zod_1.z.object({
    name: zod_1.z.string(),
    validTime: zod_1.z.string(),
    deductible: zod_1.z.string(),
});
exports.LahiTapiolaInsuranceInfoSchema = zod_1.z.object({
    v: zod_1.z.literal('1'),
    alvDeductible: zod_1.z.union([zod_1.z.boolean(), zod_1.z.null()]),
    vehicleInfo: zod_1.z.object({
        registrationNumber: zod_1.z.string().optional(),
        vehicleType: zod_1.z.string().optional(),
        markAndModel: zod_1.z.string().optional(),
        owner: zod_1.z.string().optional(),
        holder: zod_1.z.string().optional(),
    }),
    liikenne: zod_1.z.object({
        validTime: zod_1.z.string().optional(),
        insurer: zod_1.z.string().optional(),
        businessId: zod_1.z.string().optional(),
        insured: zod_1.z.string().optional(),
    }),
    kasko: zod_1.z.object({
        validTime: zod_1.z.string().optional(),
        insurer: zod_1.z.string().optional(),
        businessId: zod_1.z.string().optional(),
        insured: zod_1.z.string().optional(),
        insurances: zod_1.z.array(exports.LahiTapiolaKaskoItemSchema).optional(),
    }),
    invoicing: zod_1.z.nativeEnum(exports.LtInvoicingInformation).optional(),
    replacementVehicleDays: zod_1.z.string().optional(),
});
