"use strict";
// https://sharedocs.arceurope.com/display/SQ/SPARX+Data+Model
Object.defineProperty(exports, "__esModule", { value: true });
exports.SparxPreferredVehicleFeature = void 0;
exports.SparxPreferredVehicleFeature = {
    AutomaticTransmission: 'AutomaticTransmission',
    ManualTransmission: 'ManualTransmission',
    TowBall: 'TowBall',
    AirConditioning: 'AirConditioning',
    Hybrid: 'Hybrid',
    Electric: 'Electric',
    Petrol: 'Petrol',
    Diesel: 'Diesel',
    UnlimitedMileage: 'UnlimitedMileage',
    ToddlerSeat: 'ToddlerSeat',
    BoosterSeat: 'BoosterSeat',
    InfantSeat: 'InfantSeat',
};
