import { useFormContext } from 'react-hook-form';
import { CaseFormShell } from './case-form-shell';
import { CaseSchema } from '@/backend/models/Case';
import { useLocale } from '@/hooks/use-language';
import { CaseFormTextField } from './case-form-text-field';
import { z } from 'zod';
import { CaseFormComboBoxField } from './case-form-combo-box-field';

export const countryList = [
  { alpha2: 'FI', alpha3: 'FIN', name: 'Suomi' },
  { alpha2: 'SE', alpha3: 'SWE', name: 'Ruotsi' },
  { alpha2: 'EE', alpha3: 'EST', name: 'Viro' },
  { alpha2: 'NO', alpha3: 'NOR', name: 'Norja' },
  { alpha2: 'DK', alpha3: 'DNK', name: 'Tanska' },
];

export function LocationSection() {
  const form = useFormContext<z.infer<typeof CaseSchema>>();
  const { t } = useLocale('case');

  const countryOptions = countryList.map((c) => ({
    value: c.alpha2.toLowerCase(),
    label: t(`case:country.${c.alpha2.toLowerCase()}`),
  }));

  const onCountrySelect = (value: string) => {
    const key = countryOptions.find(
      (c) => c.label.toLowerCase() === value
    )!.value;
    const country = countryList.find((c) => c.alpha2.toLowerCase() === key);
    form.setValue('location.country.name', country!.name);
    form.setValue('location.country.alfa2', country!.alpha2);
    form.setValue('location.country.alfa3', country!.alpha3);
  };

  return (
    <CaseFormShell title={t('case:new.location.title')}>
      <div className="grid gap-6">
        <CaseFormTextField
          {...form}
          name="location.address"
          label={t('case:new.location.address.label')}
          placeholder={t('case:new.location.address.placeholder')}
          description={t('case:new.location.address.description')}
        />
        <CaseFormTextField
          {...form}
          name="location.city"
          label={t('case:new.location.city.label')}
          placeholder={t('case:new.location.city.placeholder')}
          description={t('case:new.location.city.description')}
        />
        <CaseFormComboBoxField
          {...form}
          name="location.country.alfa2"
          label={t('case:new.location.country.label')}
          placeholder={t('case:new.location.country.placeholder')}
          description={t('case:new.location.country.description')}
          options={countryOptions}
          onSelect={onCountrySelect}
        />
      </div>
    </CaseFormShell>
  );
}
