"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusChanged = exports.isOutgoing = exports.OutgoingMessageReceiver = void 0;
var OutgoingMessageReceiver;
(function (OutgoingMessageReceiver) {
    OutgoingMessageReceiver["innovoice"] = "innovoice";
    OutgoingMessageReceiver["interhinaus"] = "interhinaus";
    OutgoingMessageReceiver["falck"] = "falck";
})(OutgoingMessageReceiver || (exports.OutgoingMessageReceiver = OutgoingMessageReceiver = {}));
function isOutgoing(message) {
    return message.sentTo !== null;
}
exports.isOutgoing = isOutgoing;
function statusChanged(msg) {
    return msg.serviceStatus !== null;
}
exports.statusChanged = statusChanged;
