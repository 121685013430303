import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';
import { Icons } from '@ui/components/icons';
import i18n from '@/i18n';
import { useLocale } from '@/hooks/use-language';
import { cn } from '@ui/lib/utils';

export function LanguageToggle() {
  const { t } = useLocale('common');
  const { lang } = useLocale();
  const handleToggle = (newLang: 'fi' | 'en') => {
    i18n.changeLanguage(newLang);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Icons.globe className="absolute h-[1rem] w-[1rem]" />
          <span className="sr-only">{t('common:language.toggle')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className={cn(
            'hover:cursor-pointer text-muted-foreground/60 hover:text-foreground',
            lang === 'fi' && 'text-foreground'
          )}
          onClick={() => handleToggle('fi')}
        >
          {lang === 'fi' ? (
            <Icons.check className="h-4 w-4 mr-2" />
          ) : (
            <Icons.circleDashed className="h-3 w-3 ml-0.5 mr-2.5" />
          )}
          <span>Suomi</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn(
            'hover:cursor-pointer text-muted-foreground/60 hover:text-foreground',
            lang === 'en' && 'text-foreground'
          )}
          onClick={() => handleToggle('en')}
        >
          {lang === 'en' ? (
            <Icons.check className="h-4 w-4 mr-2" />
          ) : (
            <Icons.circleDashed className="h-3 w-3 ml-0.5 mr-2.5" />
          )}
          <span>English</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
