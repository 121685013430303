import { z } from 'zod';
import { CaseFormTextField } from './case-form-text-field';
import { CaseFormShell } from './case-form-shell';
import { CaseSchema } from '@/backend/models/Case';
import { useFormContext } from 'react-hook-form';
import { useLocale } from '@/hooks/use-language';

export function ClientSection() {
  const form = useFormContext<z.infer<typeof CaseSchema>>();
  const { t } = useLocale('case');

  return (
    <CaseFormShell title={t('case:new.client.title')}>
      <div className="grid gap-6">
        <CaseFormTextField
          {...form}
          name="client.fullName"
          label={t('case:new.client.name.label')}
          placeholder={t('case:new.client.name.placeholder')}
          description={t('case:new.client.name.description')}
        />
        <CaseFormTextField
          {...form}
          name="client.phoneNumber"
          label={t('case:new.client.phone.label')}
          placeholder={t('case:new.client.phone.placeholder')}
          description={t('case:new.client.phone.description')}
        />
      </div>
    </CaseFormShell>
  );
}
