import * as React from 'react';
import { bus } from '../context/bus';

interface Props {
  callback: () => Promise<unknown>;
}

export const useRealtime = ({ callback }: Props) => {
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pokeHandler = (_params: any) => {
      callback();
    };

    bus.on('poke', pokeHandler);

    return () => {
      bus.off('poke', pokeHandler);
    };
  }, [callback]);
};
