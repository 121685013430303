import * as React from 'react';
import { TailwindIndicator } from '@/components/layout/tailwind-indicator';
import { Toaster as HotToaster } from 'react-hot-toast';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { trpc } from '@/lib/trpc';
import superjson from 'superjson';
import { useUser } from '@/hooks/use-auth';
import { useTheme } from '@/hooks/use-theme';
import { store } from '@/context/auth';

export function RootLayout({ children }: { children: React.ReactNode }) {
  const user = useUser();

  const { theme, systemTheme } = useTheme();

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            try {
              const { message } = error as { message: string };
              if (message === 'signature verification failed') {
                store.remove();
                location.href = import.meta.env.VITE_APP_URL;
              }
            } catch (err) {
              console.error(err);
            }
          },
        }),
      })
  );
  const [trpcClient] = React.useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: import.meta.env.VITE_API_URL,
          async headers() {
            return {
              authorization: `Bearer ${user?.token}`,
            };
          },
        }),
      ],
      transformer: superjson,
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <div className="min-h-dvh font-sans antialiased bg-background">
          {children}
          <TailwindIndicator />
          <HotToaster
            toastOptions={{
              className: '',
              style: {
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                fontWeight: '500',
                color:
                  theme === 'dark' ||
                  (theme === 'system' && systemTheme === 'dark')
                    ? 'hsl(var(--primary-foreground))'
                    : 'hsl(var(--primary))',
                backgroundColor:
                  theme === 'dark' ||
                  (theme === 'system' && systemTheme === 'dark')
                    ? 'hsl(var(--foreground))'
                    : 'hsl(var(--background))',
              },
            }}
          />
        </div>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
