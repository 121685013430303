import { Row } from '@tanstack/react-table';

import { Button } from '@ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@ui/components/dropdown-menu';

import { Icons } from '@ui/components/icons';
import { UserSchema, UserStatus } from '@/backend/models/User';
import { useLocale } from '@/hooks/use-language';
import { trpc } from '@/lib/trpc';
import toast from 'react-hot-toast';

interface RejectedUserTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function RejectedUserTableRowActions<TData>({
  row,
}: RejectedUserTableRowActionsProps<TData>) {
  const user = UserSchema.parse(row.original);

  const { t, lang } = useLocale('users');

  const utils = trpc.useUtils();

  const { mutate: confirmMutate, isLoading: isConfirmLoading } =
    trpc.user.setUserStatus.useMutation({
      async onSuccess() {
        await utils.user.invalidate();
        toast.success(t('users:confirmedSuccessfully'));
      },
    });

  const { mutate: deleteMutate, isLoading: isDeleteLoading } =
    trpc.user.delete.useMutation({
      async onSuccess() {
        await utils.user.invalidate();
        toast.success(t('users:deletedSuccessfully'));
      },
    });

  const confirmUser = async () =>
    confirmMutate({
      userId: user.id!,
      status: UserStatus.CONFIRMED,
      emailLang: lang as 'fi' | 'en',
    });

  const deleteUser = async () => deleteMutate(user.id!);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <Icons.more className="h-4 w-4"></Icons.more>
          <span className="sr-only">{t('users:actions.openMenu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={(event) => {
            event.preventDefault();
            confirmUser();
          }}
        >
          {isConfirmLoading && (
            <Icons.spinner className="h-4 w-4 mr-2 animate-spin" />
          )}
          {t('users:actions.confirm')}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={(event) => {
            event.preventDefault();
            deleteUser();
          }}
        >
          {isDeleteLoading && (
            <Icons.spinner className="h-4 w-4 mr-2 animate-spin text-destructive" />
          )}
          <span className="text-destructive">{t('users:actions.delete')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
