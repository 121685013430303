import { z } from 'zod';
import { BaseEntitySchema } from './BaseEntity';
import { UserSchema } from './User';
import { HertzOrderFormCaseUpdateSchema } from 'shared';

export const SituationType = {
  1: 'Polttoaine loppu',
  2: 'Väärä tankkaus',
  3: 'Itseaiheutettu',
  4: 'Rengasrikko',
  5: 'Liikennevahinko',
  6: 'Ajoneuvo ei käynnisty (akku loppu)',
  7: 'Ajoneuvo ei käynnisty (muu)',
  8: 'Tekninen vika (sammui ajosta)',
  9: 'Tekninen vika (moottori)',
  10: 'Tekninen vika (vaihteisto)',
  11: 'Tekninen vika (voimansiirto)',
  12: 'Tekninen vika (alustanosat)',
  13: 'Tekninen vika (jarrut)',
  14: 'Tekninen vika (korinosat - ikkunat, ovet, lukot)',
  15: 'Uloslukitus',
  16: 'Häiriövalo keltainen (oireeton)',
  17: 'Aiheeton puhelu',
  18: 'Kaskovahinko',
  19: 'Hirvieläinvahinko',
  20: 'Jäätynyt latauskaapeli',
} as const;
export type SituationType = (typeof SituationType)[keyof typeof SituationType];

// these are ARC drive train types
export const DriveTrain = {
  FRONT_WHEEL: 'FrontWheelDrive',
  REAR_WHEEL: 'RearWheelDrive',
  FOUR_WHEEL: 'FourWheelDrive',
  // ALL_WHEEL: 'AllWheelDrive',
} as const;
export type DriveTrain = (typeof DriveTrain)[keyof typeof DriveTrain];

export const CaseStatus = {
  CREATED: 'created',
  WAITING_PROCESSING: 'waiting_processing',
  IN_PRORGESS: 'in_progress',
  ASSISTANCE_ON_THE_WAY: 'assistance_on_the_way',
  READY: 'ready',
  CANCELLED: 'cancelled',
  CANCELLATION_REQUESTED: 'cancellation_requested',
  CANCELLATION_FAILED: 'cancellation_failed',
  SENDING_FAILED: 'sending_failed',
} as const;
export type CaseStatus = (typeof CaseStatus)[keyof typeof CaseStatus];

export const CaseSchema = BaseEntitySchema.extend({
  caseNumber: z.string().min(6).max(6).optional(),
  assistanceId: z.string().optional(),
  assistanceCaseNumber: z.string().optional(),
  status: z.nativeEnum(CaseStatus).default(CaseStatus.CREATED),
  client: z.object({
    fullName: z.string().min(1),
    phoneNumber: z.string().min(1),
  }),
  vehicle: z.object({
    registrationPlate: z.string().min(1),
    make: z.string().min(1),
    model: z.string().min(1),
    mileage: z.coerce.number().min(1),
    driveTrain: z.nativeEnum(DriveTrain),
  }),
  location: z.object({
    city: z.string().min(1),
    address: z.string().min(1),
    country: z.object({
      name: z.string().min(1),
      alfa2: z.string().min(2).max(2),
      alfa3: z.string().min(3).max(3),
    }),
  }),
  situation: z.object({
    situationType: z.object({
      id: z.string(),
      type: z.nativeEnum(SituationType),
    }),
    animalsOnBoard: z.boolean(),
    hasTrailer: z.boolean(),
    personCount: z.coerce.number(),
  }),
  comments: z.string().optional(),
  creator: UserSchema.pick({
    id: true,
    email: true,
    name: true,
  }).required({ id: true }),
  invoice: z
    .object({
      readyAt: z.string().datetime(),
      fulfilledServices:
        HertzOrderFormCaseUpdateSchema.shape.fulfilledServices.unwrap(),
      // if HertzOrderFormCaseUpdateSchema is discriminated union we need to use the lower
      // HertzOrderFormCaseUpdateSchema.options[0].shape.fulfilledServices.unwrap(),
    })
    .optional(),
});

export type Case = z.infer<typeof CaseSchema>;
